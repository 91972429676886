<template>
<section class="icons">
    <div class="icons__main custom-container">
        <h2 v-html="data.title"></h2>
        <div class="icons__main--wrapper">
            <div class="icons__main--wrap" v-for="(item, index) in data.wrap" :key="index">
                <div class="icons__main--icon" v-if="item.icon" :style="{ backgroundImage: 'url(' + item.icon + ')' }"></div>
                <p v-if="item.text" v-html="item.text"></p>
            </div>
        </div>

    </div>
</section>
</template>

<script>
export default {
    name: "Icons",
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.icons {
    padding: 100px 0;

    @include breakpoint-max("mobile") {
        padding: 20px 0;

    }

    &__main {

        h2 {
            font-size: 2.2em;
            font-weight: 300;
            margin-bottom: 60px;
        }

        &--wrapper {
            display: flex;

            @include breakpoint-max("mobile") {
                flex-direction: column;
            }

        }

        &--wrap {
            width: 230px;
            margin-right: 110px;
            padding-bottom: 20px;
            border-bottom: 2px solid lightgrey;

            @include breakpoint-max("mobile") {
                width: 100%;
                margin: 0px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

        }

        &--icon {
            margin-bottom: 20px;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat:
                no-repeat;
            filter: invert(14%) sepia(20%) saturate(885%) hue-rotate(198deg) brightness(99%) contrast(93%);

        }
    }

}
</style>
