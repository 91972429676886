<template>
    <section class="only-text">
        <div class="only-text__bg"></div>
        <div class="custom-container">
            <div v-html="data.text" class="only-text__content" data-aos="fade-down"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OnlyText",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.only-text {
    position: relative;
    &__bg {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #F5F5F5;
        top: 0;
        left: 0;
    }
    &__content {
        padding: 100px 40px;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        margin: 0 auto;
        @include breakpoint-max("s-tablet") {
            padding: 50px 10px;
        }
        @include breakpoint-max("xs-tablet") {
            padding: 0 0 50px;
        }
        /deep/ h2 {
            font-size: 36px;
            letter-spacing: 0;
            font-weight: 300;
            text-transform: uppercase;
            line-height: 1.3;
            color: $darkBlueHeading;
            @include breakpoint-max("s-tablet") {
                font-size: 28px;
            }
            @include breakpoint-max("mobile") {
                font-size: 24px;
            }
            /deep/ span {
                color: $lightBlue;
            }
        }
        /deep/ a {
            margin-top: 20px;
        }
    }
}
</style>
