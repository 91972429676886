<template>
<main>
    <flexible-content />

    <section class="section view-single is-paddingless" v-if="page && page.content">

        <div class="custom-container full-post" v-if="page.thumbnail">
            <div class="full-post--title">
                <h1 v-if="page.category[0].slug === 'blog'">Blog</h1>
                <h1 v-if="page.category[0].slug === 'dziennik'">Dziennik budowy</h1>
                <div class="full-post__data">
                    <router-link v-if="page.category[0].slug === 'blog'" to="/blog">
                        <button>Powrót do bloga</button>
                    </router-link>
                    <router-link v-if="page.category[0].slug === 'dziennik'" to="/dziennik-budowy">
                        <button>Powrót do dziennika budowy</button>
                    </router-link>

                </div>

            </div>

            <div class="columns is-paddingless is-marginless is-centered">
                <div class="column is-paddingless is-marginless is-12">
                    <div class="content image-wrapper">
                        <figure class="image is-2by1">
                            <img :src="page.thumbnail" :alt="page.title">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <div class="custom-container full-post">
            <div class="box">
                <div class="columns is-paddingless is-marginless is-centered">
                    <div class="column is-10">

                        <div class="content">
                            <h1 class="title" v-html="page.title"></h1>
                            <div class="txt" v-if="page.content" v-html="page.content"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons" v-if="page.category[0].slug === 'blog'">
                <router-link :to="prevPost">
                    <button>Poprzedni Post</button>
                </router-link>
                <router-link :to="nextPost">
                    <button>Następny Post</button>
                </router-link>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import FlexibleContent from '@/components/FlexibleContent.vue'
export default {
    components: {
        'flexible-content': FlexibleContent
    },
    computed: {
        page() {
            return this.$store.getters.getPageByPath(this.$route.path)
        },
        posts() {
            return this.$store.getters.getPosts
        },
        logBuilding() {
            return this.$store.getters.getLogBuildingContent

        },
        postPosition() {
            const allPosts = this.posts
            let pos = allPosts.map(function (e) { return e.id }).indexOf(this.page.id)

            return pos
        },
        nextPost() {
            if (this.postPosition + 1 > this.posts.length - 1) {
                return this.posts[0].slug
            } else {
                return this.posts[this.postPosition + 1].slug
            }
        },
        prevPost() {
            if (this.postPosition - 1 < 0) {
                return this.posts[this.posts.length - 1].slug
            } else {
                return this.posts[this.postPosition - 1].slug
            }
        }
    }
}
</script>

<style lang="scss">
@import "../styles/framework/variables.sass";

.content {
    figure {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .title {
        margin-top: 30px !important;
        font-weight: bold !important;
        font-family: 'Poppins', sans-serif;
        color: $darkBlueHeading;
    }

    .txt {
        padding: 20px 0;

        @include mobile {
            padding: 10px 0;
        }
    }
}

.full-post {
    background-color: $secondBackgroundColor;
    max-width: 1100px;

    &--title {
        font-size: 2.2em;
        padding: 50px 0;
        display: flex;
        align-items: center;
    }

    &__data {
        margin-left: 100px;

        button {
            border: 0;
            font-size: 1rem;
            color: $granat;
            outline: none;
            cursor: pointer;
            position: relative;
           
            &::before {
                content: '';
                height: 15px;
                width: 15px;
                background-image:
                    url('~@/assets/arrow-left.svg');
                background-size: cover;
                position: absolute;
                top: 4px;
                left: -25px;
                filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
            }

        }
    }
}

.box {
    position: relative;
    margin-bottom: 0 !important;
    background-color: $secondBackgroundColor !important;
    box-shadow: none !important;
    color: $darkGranat !important;

}

.buttons {
    position: absolute;
    margin: 0 auto;
    right: 40px;

    a {
        button {
            outline: none;
            text-transform: uppercase;
            border: none;
            color: #FFF;
            padding: 10px 20px;
            font-size: 1rem;
            letter-spacing: 1px;
            cursor: pointer;
        }

        &:nth-child(1) {
            button {
                background-color: $granat;
            }
        }

        &:nth-child(2) {
            button {
                background-color: $lightBlue;
            }
        }
    }

    @include mobile {
        left: 0;
        right: auto;
    }
}

.view-single {
    margin-bottom: 50px;
}
</style>
