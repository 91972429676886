import Vue from 'vue'
import VueTagManager from "vue-tag-manager"
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import Meta from 'vue-meta'
import VueI18n from 'vue-i18n'
import Router from 'vue-router'
import Page from './views/Page.vue'
import PageNotFound from './views/PageNotFound.vue'
import Smart from './components/Smart.vue'
import VueScrollmagic from 'vue-scrollmagic'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'aos/dist/aos.css'
import store from './store'
import axios from 'axios'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

// import styles
require('./styles/main.sass')

store.dispatch('fetchContent')
.then(() => {
    // use addons
    Vue.use(VueTagManager, {
        gtmId: 'GTM-K9XDDM4'
    })
    Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
    Vue.config.productionTip = false
    Vue.use(VueScrollTo,{ offset: -80 })
    Vue.use(Meta)
    Vue.use(VueI18n)
    Vue.use(Router)
    Vue.use(VueScrollmagic)
    Vue.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyAafKyLVdzwvjDQPle6j66Yhqu1R8IjYYA',
			libraries: 'places',
		},
	})

    
    const publicPath = '/' + store.getters.getPublicPath + '/'
    const locale = store.getters.getLocale
    const defaultLocale = store.getters.getOptions.default_language
    // initialize router
    const router = new Router({
        mode: 'history',
        base: locale != defaultLocale ? publicPath + locale + '/' : publicPath,
        routes: [
            { path: '/znajdz-mieszkanie', component: Smart },
            { path: '/'+defaultLocale, redirect: { name: 'home' } },
            { path: '/'+defaultLocale+'/:id', redirect: '/:id' },
            { path: '/'+defaultLocale+'/:parent/:id', redirect: '/:parent/:id' },
            { path: '/', name: 'home', component: Page },
            { path: '/:id', component: Page },
            { path: '/:parent/:id', component: Page },
            { path: '/404', name: 'error404', component: PageNotFound },
            { path: '*', redirect: { name: 'error404' }},
        ],
        scrollBehavior(to) {
            if (to.hash) {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        VueScrollTo.scrollTo(to.hash, 1000);
                        resolve({ selector: to.hash })
                    }, 1000)
                })
            } else {
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve({ x: 0, y: 0 })
                    }, 600)
                })
            }
        }
    })
    // config langs
    const i18n = new VueI18n({
        locale: (locale.trim().length && locale != "/") ? locale : defaultLocale,
        fallbackLocale: defaultLocale
    })
    // Google analytics
    if(process.env.VUE_APP_GA){
        Vue.use(VueAnalytics, {
            id: process.env.VUE_APP_GA,
            debug: {
                sendHitTask: process.env.NODE_ENV === 'production'
            },
            router
        })
    }
    // create Vue instance
    const vm = new Vue({
        router,
        i18n,
        store,
        render: h => h(App)
    })
    vm.$mount('#app')
    //  emit "render-event" after fetching data from api endpoint
    //document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('render-ready'));
})
.catch((error) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    var bodyFormData = new FormData();
    bodyFormData.set('error', error);
    bodyFormData.set('website', window.location);
    axios.post('https://api.betterandmore.eu/bedrock/wp-json/better-api/v1/send', bodyFormData, config)
});
