<template>
<section class="search" data-aos="fade-up">
    <div class="custom-container-full search__wrapper">
        <div class="search__slider--swiper">
            <template>
                <swiper ref="mySwiper" :options="swiperOptions">
                    <swiper-slide>
                        <div class="slider__image" :style="{backgroundImage:'url('+ data.slider[0].background_image +')'}">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[0].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[0].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>

                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                        <p v-html="data.slider[0].slogan"></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider__image" :style="{ backgroundImage: 'url(' + data.slider[1].background_image + ')' }">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[1].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[1].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>

                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                        <p v-html="data.slider[1].slogan"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider__image" :style="{ backgroundImage: 'url(' + data.slider[2].background_image + ')' }">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[2].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[2].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>
                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                         <p v-html="data.slider[2].slogan"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider__image" :style="{ backgroundImage: 'url(' + data.slider[3].background_image + ')' }">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[3].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[3].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>
                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                        <p v-html="data.slider[3].slogan"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider__image" :style="{ backgroundImage: 'url(' + data.slider[4].background_image + ')' }">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[4].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[4].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>
                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                        <p v-html="data.slider[4].slogan"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slider__image" :style="{ backgroundImage: 'url(' + data.slider[5].background_image + ')' }">
                            <div class="slider__image--appla">
                                <div class="slider__image--wrapper">
                                    <h1 class="slider__image--title" v-html="data.slider[5].title"></h1>
                                    <p class="slider__image--description" v-html="data.slider[5].description"></p>
                                    <div class="slider__image--button">
                                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Wyszukaj mieszkanie
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                                        </router-link>
                                        <router-link to="/search-apartments" v-if="locale == 'en'">Search Apartments
                                        </router-link>
                                    </div>
                                    <div class="slider__image--key-wrapper">
                                        <div class="slider__image--key">
                                        </div>
                                        <p v-html="data.slider[5].slogan"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>

                    <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </template>
        </div>
        <div class="search__content">
            <div class="search__image">
                <img :src="data.image" alt="logo" ref="image" />
            </div>
            <div class="search__wrap">
                <h2 v-if="locale == 'pl'" v-html="'Znajdź mieszkanie dla swojej rodziny'"></h2>
                <p v-if="locale == 'pl'" v-html="'Sprzedaż mieszkań w ramach inwestycji U-CITY nabiera tempa – to osiedle bardzo odróżnia się od okolicznych nowych osiedli. Nie zwlekaj i już dziś prześlij swoje zapytanie!'"></p>
                <!-- <h2 v-html="data.title"></h2>
                <div class="search__inputs">
                    <select v-model="building" @change="changeBuildingState(building)">
                        <option v-for="(item, index) in data.building" :key="index" :value="item.value" v-html="item.title"></option>
                    </select>
                    <select v-model="floor" @change="changeLevelState(floor)">
                        <option v-for="(item, index) in data.floor" :key="index" :value="item.value" v-html="item.title"></option>
                    </select>
                    <select v-model="rooms" @change="changeRoomsState(rooms)">
                        <option v-for="(item, index) in data.rooms" :key="index" :value="item.value" v-html="item.title"></option>
                    </select>
                </div> -->
                <!-- <div class="search__yardage">
                    <h3 v-if="locale == 'pl'">Metraż</h3>
                    <h3 v-if="locale == 'en'">Area</h3>
                    <h3 v-if="locale == 'ua'">Метраж</h3>
                    <span class="full-search__left-value">{{ value[0] }}</span>
                    <div class="search__slider">
                        <vue-slider v-model="value" :process="true" :min="min" :max="max" @change="changeMinMaxState(value)"></vue-slider>
                    </div>
                    <div class="search__value2">
                        <span class="full-search__right-value">{{ value[1] }}</span>
                    </div>
                </div> -->
                <div class="search__button-flex">
                    <router-link to="/znajdz-mieszkanie" class="search__button" v-if="locale == 'pl'">Szukaj</router-link>
                    <router-link to="/search-apartments" class="search__button" v-if="locale == 'ua'">Пошук</router-link>
                    <router-link to="/search-apartments" class="search__button" v-if="locale == 'en'">Search</router-link>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default {
    name: 'carrousel',
    name: 'swiper-example-pagination-custom',
    title: 'Custom pagination',

    data() {
        return {
        
            value: this.$store.getters.getValue(),
            min: this.$store.getters.getMin(),
            max: this.$store.getters.getMax(),
            building: this.$store.getters.getBuilding(),
            floor: this.$store.getters.getLevel(),
            rooms: this.$store.getters.getRooms(),
            allInvestments: this.$store.getters.getInvestmentsContent(),
            swiperOptions: {
                autoplay: {
                    delay: 5000,
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: 'true',
                    renderBullet(index, className) { return `<span class="${className} swiper-pagination-bullet-custom">0${index + 1}</span>` }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }

            },
        }
    },
    components: {
        VueSlider,
        Swiper,
        SwiperSlide,

    },
    directives: {
        swiper: directive
    },

    methods: {
        changeBuildingState(value) {
            this.$store.commit("SET_BUILDING", value);
        },
        changeLevelState(value) {
            this.$store.commit("SET_LEVEL", value);
        },
        changeRoomsState(value) {
            this.$store.commit("SET_ROOMS", value);
        },
        changeMinMaxState(value) {
            this.$store.commit("SET_VALUE", value.slice(0, 2));
        },
    },
    computed: {
        getMinArea() {
            return Math.min.apply(null, this.allValues);
        },
        getMaxArea() {
            return Math.max.apply(null, this.allValues);
        },
        locale() {
            return this.$store.getters.getLocale;
        },
        swiper() { return this.$refs.mySwiper.$swiper }

    },
    mounted() {
        console.log('Current Swiper instance object', this.swiper)
    }

};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.search {
    background-color: $darkGranat;
    color: #fff;
    &__image {
        max-width: 250px;
        padding: 15px 35px;
        img {
            width: 100%;
        }
    }
    &__button-flex {
        display: flex;
        justify-content: flex-end;
    }

    &__button {
        background-color: $red;
        padding: 15px;
        display: block;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-top: 15px;
        bottom: 0;
    }

    &__content {
        h2 {
            color: #fff;
            text-align: center;
            font-size: 2.2em;
            font-weight: 300;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
    }

    &__yardage {
        padding: 10px;
        display: flex;
        width: 100%;

        span,
        h3 {
            flex: 1;
        }

        h3 {
            margin-right: 40px;
        }
    }

    &__slider {
        flex: 10;
        padding: 5px 10px;

        @include breakpoint-max("tablet") {
            flex: 6;
        }

        @include breakpoint-max("mobile") {
            flex: 2;
        }
    }

    &__value2 {
        flex: 1;
        text-align: right;
    }

    &__inputs {
        display: flex;
        flex-direction: column;

        @include breakpoint-max("tablet") {
            flex-direction: column;
        }
    }

    select {
        flex: 1;
        width: 100%;
        outline: none;
        border: 1px solid #646688;
        border-radius: none;
        margin: 10px 0;
        font-size: 18px;
        appearance: none;
        border-radius: 0;
        padding: 15px;
        color: #fff;
        background-color: $darkGranat;
        font-weight: 300;
    }

    &__left-value {
        @include breakpoint-max("tablet") {
            margin-left: 20px;
        }
    }

    &__right-value {
        margin-left: 20px;
    }

    &__wrapper {
        display: flex;
        flex-direction: row-reverse;
        height: 90vh;

        @include breakpoint-max("large") {
            height: 90vh;
        }

        @include breakpoint-max("tablet") {
            flex-direction: column;
            height: auto;
        }

    }

    &__slider--swiper {
        flex: 3;
        background: #fff;
        color: #000;

        .swiper-slide {
            width: 100% !important
        }

    }

    &__content {
        flex: 1;
        width: 100%;
        max-width: 606px;
        min-width: 606px;
        padding: 0 68px;
        align-items: center;
        background-color: $darkGranat;

        @include breakpoint-max("large") {
            max-width: 450px;
            min-width: 450px;
            padding: 0 50px;
        }

        @include breakpoint-max("desktop") {
            max-width: 410px;
            min-width: 410px;
        }

        @include breakpoint-max("tablet") {
            padding: 50px;
            max-width: 100%;
            min-width: 100%;

        }

    }

    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 85%;
        width: 85%;

        @include breakpoint-max("large") {
            justify-content: flex-start;
            width: 100%;
        }

    }

}

.slider {

    &__image {
        height: 90vh;
        width: 100%;
        background-size: cover;
        background-position: bottom;

        &--appla {
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            position: absolute;
        }

        &--wrapper {
            height: 90%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            margin-left: 200px;
            width: 40%;
            position: relative;

            @include breakpoint-max("large") {
                margin-left: 100px;
                width: 50%;
            }

            @include breakpoint-max("mobile") {
                margin-left: 20px;
                width: 70%;
            }

        }

        &--key-wrapper {
            display: flex;
            position: absolute;
            bottom: 20px;
            right: -50%;
            color: #fff;

            @include breakpoint-max("large") {
                bottom: 50px;
            }

            @include breakpoint-max("mobile") {
                position: static;
                margin-top: 10px;
            }

            p {
                line-height: 1em;
                margin-left: 15px;
            }
        }

        &--key {
            content: '';
            height: 34px;
            width: 34px;
            background-image: url('~@/assets/key.svg');
            background-size: cover;
            filter: invert(100%) sepia(1%) saturate(10%) hue-rotate(281deg) brightness(107%) contrast(100%);
            @include breakpoint-max("tablet") {
                min-width: 35px;
            }
            @include breakpoint-max("mobile") {
                width: 25px;
            }
        }

        &--title {
            font-size: 2.2em;
            color: #fff;
            line-height: 1.2em;
        }

        &--description {
            font-size: 1.1em;
            color: #fff;
            margin: 50px 0;
            font-weight: 300;

            @include breakpoint-max("large") {
                margin: 35px 0;
            }

            @include breakpoint-max("mobile") {
                margin: 25px 0;
            }

            span {
                font-weight: 700;
            }
        }

        &--button {
            width: 200px;
            height: 55px;
            background: #fff;

            a {
                color: #28293D;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            &:hover a {
                transition: all 0.5s;
                color: $red;
            }
        }
    }
}
</style>
