<template>
<section class="map onlyMap" :class="data.custom_class">
    <div class="map__background-div"></div>
    <div class="map__container custom-container custom-container-full">
        <div class="map__map-box" data-aos="fade-up">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.280324855973!2d20.8563942!3d52.201953100000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4719353dc8072731%3A0xaf9a3270b66e015c!2sHenryka%20Brodatego%2016%2C%2002-496%20Warszawa!5e0!3m2!1spl!2spl!4v1672831623754!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            <!-- <gmap-map :center="{ lat: 52.202194, lng: 20.855613 }" :zoom="14" :options="mapStyle" class="map__iframe">
                <GmapMarker :key="index" v-for="(m, index) in data.repeater" :icon="m.icon.url" :position="{ lat: replace(m.lat), lng: replace(m.lng) }" @mouseover="openInfoWindowTemplate(m.lat, m.lng, m.title)" @mouseout="infoWindow.open = false" @click="openOverlay(m.title, m.address, m.description)" />
                <gmap-info-window :options="{ maxWidth: 300 }" :position="infoWindow.position" :opened="infoWindow.open">
                    <div>
                        <h2>{{ infoWindow.title }}</h2>
                    </div>
                </gmap-info-window>
            </gmap-map> -->
        </div>
    </div>
</section>
</template>

<script>
const mapStyle = [{
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [{ weight: "1.00" }]
    },
    {
        featureType: "all",
        elementType: "geometry.stroke",
        stylers: [{ color: "#9c9c9c" }]
    },
    {
        featureType: "all",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }]
    },
    {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }]
    },
    {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }]
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ color: "#dddddd" }]
    },
    { featureType: "poi", elementType: "all", stylers: [{ visibility: "off" }] },
    {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }]
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ color: "#eeeeee" }]
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#7b7b7b" }]
    },
    {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }]
    },
    {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }]
    },
    {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "all" }]
    },
    {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "on" }]
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#46bcec" }, { visibility: "on" }]
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#c8d7d4" }]
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#070707" }]
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }]
    }
];

export default {
    name: "OnlyMap",
    data() {
        return {
            mapStyle: { styles: mapStyle },
            infoWindow: {
                position: { lat: 0, lng: 0 },
                open: false,
                title: ""
            },
            overlay: {
                toggle: false,
                title: "",
                description: "",
                address: ""
            }
        };
    },
    methods: {
        replace(p) {
            return parseFloat(p.replace(",", ".").replace(" ", ""));
        },
        openInfoWindowTemplate(lat, lng, title) {
            this.infoWindow.position = {
                lat: this.replace(lat) + 0.0026,
                lng: this.replace(lng)
            };
            this.infoWindow.open = true;
            this.infoWindow.title = title;
        },
        openOverlay(title, address, description) {
            this.overlay.toggle = true;
            this.overlay.title = title;
            this.overlay.address = address;
            this.overlay.description = description;
        }
    },
    computed: {
        locale() {
            return this.$store.getters.getLocale;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.extra-p-y {
    padding: 120px 0 0;

    @include breakpoint-max("tablet") {
        padding: 80px 0;
    }

    @include breakpoint-max("s-tablet") {
        padding: 60px 0;
    }

    @include breakpoint-max("xs-tablet") {
        padding: 20px 0 100px 0;
    }
}

.mobile-col-reverse {
    .map__container {
        @include breakpoint-max("xs-tablet") {
            flex-direction: column-reverse;
        }
    }
}

.map {
    position: relative;
    color: $darkGranat;
    font-weight: 300;

    &__background-div {
        background-color: $secondBackgroundColor;
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 700;
    }

    &__container {
        z-index: 800;
        display: flex;

        @include breakpoint-max("xs-tablet") {
            flex-direction: column;
        }
    }

    &__map-box {
        flex: 100%;
        width: 100%;
        min-height: 640px;
        background-size: cover;
        background-position: center;

        @include breakpoint-max("large") {
            min-height: 400px;
        }

        @include breakpoint-max("xs-tablet") {
            min-height: 300px;
        }

        &-text {
            width: 60%;
            margin: auto;
            position: relative;
            margin-bottom: 100px;

            span {
                font-weight: 700;
            }

            h2 {
                font-size: 2.2em;
                line-height: 1em;
                margin: 20px 0 30px 0;
            }

            &::before {
                content: "";
                display: block;
                height: 70px;
                width: 50px;
                border-bottom: 2px solid $darkGranat;
                position: absolute;
                left: -80px;
            }
        }
    }

    &__text-box {
        flex: 0%;
        padding: 0;
        font-size: 18px;
        font-weight: 300;
        display: block;

        &-main-wrap {
            display: flex;

            @include breakpoint-max("mobile") {
                flex-direction: column;
            }
        }

        &-column-one {
            margin-right: 50px;
            min-width: 225px;

            @include breakpoint-max("mobile") {
                min-width: 100px;
                margin-right: 0;
            }
        }

        &-column-two {
            min-width: 225px;

            @include breakpoint-max("mobile") {
                min-width: 100px;
            }
        }

        &-wrap {
            border-bottom: 2px solid lightgray;
            padding: 30px 0;

            @include breakpoint-max("mobile") {
                text-align: center;
            }

            h3 {
                font-size: 2em;
            }

            &-icon {
                width: 20px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                filter: invert(14%) sepia(20%) saturate(885%) hue-rotate(198deg) brightness(99%) contrast(93%);

                @include breakpoint-max("mobile") {
                    margin: auto;
                }
            }
        }

        &-more {
            margin: 20px 0;

            a {
                position: relative;

                &::after {
                    content: "";
                    height: 15px;
                    width: 15px;
                    background-image: url("~@/assets/arrow-right.svg");
                    background-size: cover;
                    position: absolute;
                    top: 5px;
                    right: -30px;
                    filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
                }
            }
        }

        @include breakpoint-max("tablet") {
            padding: 80px 60px;
        }

        @include breakpoint-max("s-tablet") {
            padding: 60px 40px;
        }

        @include breakpoint-max("xs-tablet") {
            padding: 40px 0;
        }
    }

    &__subheading {
        color: $lightBlue;
        text-transform: uppercase;
        font-size: 18px;
        margin-bottom: 5px;
        display: inline-block;
    }

    &__heading {
        color: $darkBlueHeading;
        font-weight: bold;
        font-size: 26px;
        letter-spacing: 0.5px;
    }

    &__heading,
    &__text {
        margin-bottom: 24px;
    }

    &__text {
        color: $darkGranat;
    }

    &__link {
        text-transform: uppercase;
        color: $darkBlueHeading;
        letter-spacing: 1.25px;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            line-height: 1;
            display: inline-block;
            background-image: url("../../assets/arrow.min.svg");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            width: 1.5rem;
            height: 1.25rem;
            vertical-align: middle;
            margin-right: 10px;
            transition: all 0.35s ease-in-out;
        }
    }

    &__googlemap {
        width: 100%;
        height: 500px;
        position: relative;
    }

    & iframe {
        width: 100%;
        height: 100%;
        max-height: 650px;

        @include breakpoint-max("mobile") {
            max-height: 350px;
        }
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $orange;
        z-index: 800;
        color: #000;

        &--margin {
            margin: 20px;

            h2 {
                font-family: "Gilroy-bold";
            }

            hr {
                background-color: #000;
                height: 1px;
            }
        }

        img {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
}
</style>
