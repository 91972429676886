<template>
<section class="section post-list is-paddingless" :class="className">
    <div class="post__all">
        <div class="custom-container">
            <div class="post__all--title">
                <h1>Blog</h1>
            </div>
            <div class="post__all--wrapper" v-for="(item,key) in posts" v-if="item.category[0].slug === 'blog'">
                <div > 
                    <div class="post_all__image-wrapper" :style="{ order: (key % 2 == 0) ? 1 : 2 }">
                        <figure class="image is-16by9" v-if="item.thumbnail">
                            <img :src="item.thumbnail" :alt="item.title" @click="$router.push(item.link)">
                        </figure>
                    </div>
                    <div class="post__all--content-wrapper" :style="{order: (key % 2 == 0) ? 2 : 1}">
                        <h2 @click="$router.push(item.link)" v-html="item.title"></h2>
                        <p>{{ shorter(item.excerpt) }}</p>
                        <div v-html="item.category[0].slug"></div>
                        <!-- <div v-html="item.excerpt"></div> -->
                        <div class="more margin-top">
                            <a :href="item.link" @click.prevent="$router.push(item.link)">Czytaj dalej</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
            <div class="columns">
                <div class="column has-text-centered">
                    <button type="button" @click="posts(5)" name="button"></button>
                </div>
            </div>
        </div> -->
</section>
</template>

<script>
export default {
    methods: {
        shorter(str) {
            return str.slice(0, 247) + '...'
        }
    },
    computed: {
        posts() {
            return this.$store.getters.getPosts
        },
        firstPost() {
            return this.$store.getters.getPosts.slice(0, 1)
        },
        postsPage() {
            return this.$store.getters.getOptionsPostsPage
        },
        locale() {
            return this.$store.getters.getLocale
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.test {
    position: relative;
}

.post-list {
    &__item {
        figure {
            img {
                padding: 40px 70px;

                @include mobile {
                    padding: 0 !important;
                }
            }
        }
    }

    &__item:nth-child(odd) {
        padding-right: 60px !important;

        @include mobile {
            padding: 0 !important;
        }
    }

    &__item:nth-child(even) {

        @include mobile {
            padding: 0 !important;
        }
    }
}

.post {
    background-color: #FFF;
    -webkit-box-shadow: 0px 2px 10px -4px rgba(128, 128, 128, 1);

    &__box {
        margin: 0 100px !important;

        @include mobile {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__chest {
        display: flex !important;
        justify-content: center;
        align-items: center;

        @include touch {
            margin-bottom: 40px;
        }
    }

    &__content {
        padding: 0 50px;

    }

    &__all {
        max-width: 1500px;
        margin: auto;
        padding-top: 60px;
        background-color: $secondBackgroundColor;

        @include breakpoint-max("large") {

            max-width: 1100px;

        }

        @include mobile {
            padding: 10px !important;
            padding-top: 30px !important;
        }

        &--title {
            font-size: 2.2em;
        }

        &--wrapper {
            margin: 50px 0;
            cursor: pointer;
        }

        &--content-wrapper {
            background-color: $darkGranat;
            padding: 50px 80px;
            color: #fff;
            width: 92%;
            margin-top: -100px;
            z-index: 999;
            position: relative;

            @include breakpoint-max("mobile") {
                margin: 0;
                width: 100%;
                padding: 20px;
            }

            h2 {
                color: #fff;
                font-size: 1.5em;
                margin-bottom: 25px;

            }

            a {
                position: relative;
                color: #fff;
                display: block;
                text-align: right;

                @include breakpoint-max("mobile") {
                    margin-right: 50px;
                }

                &::after {
                    content: '';
                    height: 15px;
                    width: 15px;
                    background-image: url('~@/assets/arrow-right.svg');
                    background-size: cover;
                    position: absolute;
                    top: 7px;
                    right: -30px;
                    filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);

                }

                &:hover {
                    color: $red;
                }
            }
        }
    }

    @include touch {
        padding-top: 80px;
        margin: 0 40px;
    }

    @include mobile {
        padding-top: 40px;
        margin: 0 10px;
    }
}

.subtitle {
    font-weight: bold !important;
    font-style: normal !important;
    font-size: 20px !important;
    color: $granat !important;
}

.arrow-text {
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
        height: 14px;
        width: 14px;
    }

    a {
        text-transform: uppercase;
        font-size: 14px;
        color: $granat;
    }
}

.content-wrapper {
    h1 {
        cursor: pointer;
    }

    @include mobile {
        margin: 30px 0 !important;
    }
}

.white-block {
    height: 180px;
    width: 100%;
    background-color: #F5F5F5;
    position: absolute;
    bottom: -1px;
}

// .image-wrapper {
//     figure {
//         height: 100%;
//         width: 100%;
//         img {
//             height: 100%;
//             object-fit: cover;
//         }
//     }
// }
.margin-top {
    margin-top: 18px;
}
</style>
