<template>
  <div id="app">
    <the-header />
    <transition name="fade" mode="out-in">
      <router-view :key="$route.params.id" />
    </transition>
    <contact />
    <the-footer />
    <cookies />
  </div>
</template>

<script>
import AOS from "aos";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import Contact from "@/components/Contact.vue";
import Cookies from "@/components/Cookies.vue"
//import Preloader from "@/components/Preloader.vue";
export default {
  data() {
    return {
      allValues: [],
      allInvestments: this.$store.getters.getInvestmentsContent(),
    };
  },
  components: {
    "the-header": TheHeader,
    "the-footer": TheFooter,
    contact: Contact,
    cookies: Cookies,
    //preloader: Preloader,
  },
  metaInfo() {
    return {
      titleTemplate: this.seoTitleTemplate,
      title: this.title,
      htmlAttrs: {
        lang: this.$store.getters.getLocale,
      },
      meta: [
        { name: "description", content: this.seoDescription },
        { name: "robots", content: this.seoRobots },
      ],
      link: [{ rel: "icon", href: this.optionsFavicon }],
    };
  },
  computed: {
    page() {
      let page = this.$store.getters.getPageByPath(this.$route.path);
      if (this.$route.path === '/znajdz-mieszkanie') {
        this.$router.push('/znajdz-mieszkanie');
      }
      else if (page === undefined) {
        this.$router.push('/');
      } else {
        return this.$store.getters.getPageByPath(this.$route.path);
      }
    },
    title() {
      if (this.page !== undefined) {
        return this.page.title;
      } else {
        return "";
      }
    },
    seoTitleTemplate() {
      return this.page.seo_title != null
        ? this.page.seo_title
        : this.$store.getters.getSeoTitleTemplate;
    },
    seoDescription() {
      return this.page.seo_description != null ? this.page.seo_description : "";
    },
    seoRobots() {
      return "index,follow";
    },
    optionsFavicon() {
      return this.$store.getters.getOptionsFavicon;
    },
    objectValuesToArray() {
      let values = [];
      for (var i = 0; i < this.allInvestments.length; i++) {
        values.push(parseFloat(this.allInvestments[i].area));
      }
      this.allValues = values;
    },
    setMinArea() {
      const min = Math.min.apply(null, this.allValues);
      this.$store.commit("SET_MIN", min);
    },
    setMaxArea() {
      const max = Math.max.apply(null, this.allValues);
      this.$store.commit("SET_MAX", max);
    },
    setValue() {
      const value = [];
      value.push(this.$store.getters.getMin(), this.$store.getters.getMax());
      this.$store.commit("SET_VALUE", value);
    },
  },
  created() {
    this.objectValuesToArray;
    this.setMinArea;
    this.setMaxArea;
    this.setValue;
    AOS.init({
      duration: 1200,
    });
  },
};

</script>
