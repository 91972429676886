<template>
<section class="full-search table">
    <div class="full-search__chosen">
        <div class="full-search__form" :class="{ opened: send }">
            <h2 v-if="locale == 'pl'">
                Zadaj pytanie dotyczące wybranych mieszkań
                <span>({{ checkedBuildings.length }})</span>
            </h2>
            <h2 v-else>
                Ask a question about selected apartments
                <span>({{ checkedBuildings.length }})</span>
            </h2>
            <button @click="send = false" class="full-search__close">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
                    <g>
                        <g>
                            <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717

L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859

c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287

l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285

L284.286,256.002z" />
                        </g>
                    </g>
                </svg>
            </button>
            <div>
                <form @submit.prevent="onSubmit">
                    <input type="text" :placeholder="name_translation" v-model="name" required>
                    <input type="email" :placeholder="email_translation" v-model="email" required>
                    <textarea v-model="message" :placeholder="message_translation"></textarea>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-marketingBuildings" name="consent-marketingBuildings" v-model="checked.acceptMarketingBuildung">
                        <label for="consent-marketingBuildings">Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. zo.o., NIP: 527 23 59
                            887, Lwowska 19, 00-660 Warszawa moich danych osobowych zawartych w niniejszym
                            formularzu kontaktowym w celu marketingowym podmiotów z grupy Acciona. Wiem, że
                            mam możliwość cofnięcia zgody w każdym czasie.
                        </label>
                    </div>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-mailBuildings" name="consent-maiBuildings" v-model="checked.acceptMailBuildung">
                        <label for="consent-mailBuildings">Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. zo.o., NIP: 527 23 59
                            887, Lwowska 19, 00-660 Warszawa, moich danych osobowych zawartych w niniejszym
                            formularzu kontaktowym w celu przesyłania mi ofert handlowych drogą
                            elektroniczną. Wiem, że mam możliwość cofnięcia zgody w każdym czasie.
                        </label>
                    </div>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-telBuildings" name="consent-teBuildings" v-model="checked.acceptTelBuildung">
                        <label for="consent-telBuildings">Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. zo.o., NIP: 527 23 59
                            887, Lwowska 19, 00-660 Warszawa, moich danych osobowych zawartych w niniejszym
                            formularzu kontaktowym w celu kontaktu telefonicznego związanego z ofertą
                            handlową. Wiem, że mam możliwość cofnięcia zgody w każdym czasie.
                        </label>
                    </div>
                   <vue-recaptcha 
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    sitekey="6LdMu_0cAAAAAAH2-oGHEAvTTY5vadLXEw3uaieF" 
                    >
                    </vue-recaptcha>
                </form>

            </div>
        </div>
    </div>
    <transition name="slide-fade">
        <div class="full-search__chosen" v-if="checkedBuildings.length > 0 && locale == 'pl'">
            <div class="full-search__chosen-left" :class="{ closed: send }">
                Wybrano: <span>{{ checkedBuildings.length }}</span>
            </div>
            <button class="full-search__chosen-right" @click="onSubmit">
                Wyślij zapytanie
            </button>
        </div>
        <div class="full-search__chosen" v-if="checkedBuildings.length > 0" v-else>
            <div class="full-search__chosen-left" :class="{ closed: send }">
                Selected: <span>{{ checkedBuildings.length }}</span>
            </div>
            <button class="full-search__chosen-right" @click="onSubmit">
                Send inquiry
            </button>
        </div>
    </transition>
    <div class="custom-container">

        <div class="full-search__content">
            <div class="full-search__content--header">
                 <div class="full-search__content--header-left">
                    <span v-html="data.title">
                    </span>
                    <h2 v-html="data.title_big"></h2>
                    <p v-html="data.title_des"></p>
                </div>
            </div>
            <div class="full-search__table">
                <table class="table is-bordered full-search__table is-fullwidth" id="table">
                    <thead>
                        <tr>
                            <th v-html="data.building_title"></th>
                            <th v-if="locale == 'pl'">Numer lokalu</th>
                            <th v-else>Apartment number</th>
                            <th v-html="data.floor_title"></th>
                            <th v-html="data.room_title"></th>
                            <th v-html="data.area_title"></th>
                            <th v-html="data.status_title"></th>
                            <th v-if="locale == 'pl'">Karta mieszkania</th>
                            <th v-else>Apartment card</th>
                            <th v-if="locale == 'pl'">Wybrane</th>
                            <th v-else>Selected</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="item.status_name == 'Dostępne'" v-for="(item, index) in filteredFlats">
                            <td>{{ item.building.toString() }}</td>
                            <td>{{item.local_number.toString()}}</td>
                            <td>{{item.floor.toString()}}</td>
                            <td>{{ item.rooms.toString() }}</td>
                            <td>{{ item.area.toString() }} m<sup>2</sup></td>
                            <td v-if="locale == 'pl' && item.status_name == 'Dostępne'" :class="item.status_name">
                                 Wolne 
                            </td>
                            <td v-else-if="locale == 'en' && item.status_name == 'Dostępne'" :class="item.status_name">
                                 Available 
                            </td>
                            <td v-else :class="item.status_name">
                                {{ item.status_name.toString() }}
                            </td>
                            <td class="full-search__show">
                                <a v-if="locale == 'pl'" :href="item.card_link.toString()" target="_blank">
                                    Pobierz plan
                                </a>
                                <a v-else :href="item.card_link.toString()" target="_blank">
                                    Download plan
                                </a>
                            </td>
                            <td>
                                <input type="checkbox" :value="
                                `Budynek: ${item.building}, Pietro: ${
                                    item.level
                                }, Pokoje: ${item.rooms}, Metraz: ${item.area} | `
                                " :id="`building${index}`" v-model="checkedBuildings" />
                                <label :for="`building${index}`" v-if="locale == 'pl'">
                                    Jestem zainteresowany</label>
                                <label :for="`building${index}`" v-else> Select</label>
                            </td>
                        </tr>
                        <tr v-if="item.status_name == 'Umowa rezerwacyjna' || item.status_name == 'Wstrzymane' || item.status_name == 'Rezerwacja ustna'" v-for="(item, index) in filteredFlats">
                            <td>{{ item.building.toString() }}</td>
                            <td>{{item.local_number.toString()}}</td>
                            <td>{{item.floor.toString()}}</td>
                            <td>{{ item.rooms.toString() }}</td>
                            <td>{{ item.area.toString() }} m<sup>2</sup></td>
                            
                            <td v-if="locale == 'pl' && item.status_name == 'Umowa rezerwacyjna'" :class="item.status_name">
                                 Rezerwacja
                            </td>
                            <td v-else-if="locale == 'pl' && item.status_name == 'Umowa deweloperska'" :class="item.status_name">
                                 Sprzedane
                            </td>
                            <td v-else-if="locale == 'pl' && item.status_name == 'Wstrzymane'" :class="item.status_name">
                                 Rezerwacja
                            </td>
                            <td v-else-if="locale == 'pl' && item.status_name == 'Umowa przedwstępna'" :class="item.status_name">
                                 Sprzedane
                            </td>
                             <td v-else-if="locale == 'pl' && item.status_name == 'Rezerwacja ustna'" :class="item.status_name">
                                 Rezerwacja
                            </td>
                            <td v-else-if="locale == 'en' && item.status_name == 'Umowa rezerwacyjna'" :class="item.status_name">
                                 Reservation
                            </td>
                            <td v-else-if="locale == 'en' && item.status_name == 'Umowa deweloperska'" :class="item.status_name">
                                 Sold
                            </td>
                            <td v-else-if="locale == 'en' && item.status_name == 'Wstrzymane'" :class="item.status_name">
                                 Reservation
                            </td>
                            <td v-else-if="locale == 'en' && item.status_name == 'Umowa przedwstępna'" :class="item.status_name">
                                 Sold
                            </td>
                             <td v-else-if="locale == 'en' && item.status_name == 'Rezerwacja ustna'" :class="item.status_name">
                                 Reservation
                            </td>
                            <td v-else :class="item.status_name">
                                {{ item.status_name.toString() }}
                            </td>
                            <td class="full-search__show">
                                <a v-if="locale == 'pl'" :href="item.card_link.toString()" target="_blank">
                                    Pobierz plan
                                </a>
                                <a v-else :href="item.card_link.toString()" target="_blank">
                                    Download plan
                                </a>
                            </td>
                            <td>
                                <input type="checkbox" :value="
                                `Budynek: ${item.building}, Pietro: ${
                                    item.level
                                }, Pokoje: ${item.rooms}, Metraz: ${item.area} | `
                                " :id="`building${index}`" v-model="checkedBuildings" />
                                <label :for="`building${index}`" v-if="locale == 'pl'">
                                    Jestem zainteresowany</label>
                                <label :for="`building${index}`" v-else> Select</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="full-search__button">
                <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'">Zobacz więcej
                </router-link>
                <router-link to="/search-apartments" v-if="locale == 'ua'">Пошук квартир
                </router-link>
                <router-link to="/search-apartments" v-if="locale == 'en'">See Apartments
                </router-link>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import VueSlider from "vue-slider-component";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import "vue-slider-component/theme/default.css";

export default {
    data() {
        return {
            flatListing: {
                xml: {
                    xml: {
                        realestate: [],
                    },
                },
            },
            currentState: false,
            status: this.$store.getters.getStatus(),
            value: this.$store.getters.getValue(),
            min: this.$store.getters.getMin(),
            max: this.$store.getters.getMax(),
            floor: 'All',
            building: 'All',
            rooms: 'All',
            allValues: [],
            allInvestments: this.$store.getters.getInvestmentsContent(),
            tip: "",
            send: false,
            lang: this.$store.getters.getLocale,
            checkedBuildings: [],
            // sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ""
            },
            name: null,
            email: null,
            message: null,
            name_translation: "Imię i nazwisko",
            email_translation: "E-mail",
            message_translation: "Wiadomość",
            correct: false,
            checked: {
                acceptMarketingBuildung: false,
                acceptMailBuildung: false,
                acceptTelBuildung: false,
            },

        };
    },
    components: {
        VueSlider,
        "vue-recaptcha": VueRecaptcha
    },
    methods: {
        injectTooltip(value) {
            let tip = document.createElement("div");
            tip.id = "tooltip";
            tip.style.padding = "15px";
            tip.style.background = "white";
            // tip.innerHTML = this.$filters.changeToTitle(value)
            tip.innerHTML = this.$options.filters.changeToTitle(value);
            tip.style.position = "fixed";
            tip.style.zIndex = "999";

            tip.style.left = event.clientX + 50 + "px";
            tip.style.top = event.clientY + "px";

            document.body.appendChild(tip);
        },
        moveTooltip() {
            let tip = document.getElementById("tooltip");
            tip.style.left = event.clientX + 50 + "px";
            tip.style.top = event.clientY + "px";
        },
        deleteTooltip(el) {
            document.body.removeChild(document.getElementById("tooltip"));
        },
        onSubmit() {

            this.send = true

            if (this.email != null) {
                this.$refs.recaptcha.execute()
            }

            if (this.locale == 'pl') {
                this.sendLabel = 'Wysyłanie...'
            } else {
                this.sendLabel = 'Sending...'
            }
        },
        onVerify(recaptchaToken) {

            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('name', this.name);
            bodyFormData.set('email', this.email);
            bodyFormData.set('message', this.message);
            bodyFormData.set('buildings', this.checkedBuildings);
            bodyFormData.set('recaptchaToken', recaptchaToken);

            if (this.checked.acceptMarketingBuildung === false) {
                bodyFormData.set('consent-marketingBuildings', '');
            } else {
                bodyFormData.set('consent-marketingBuildings', 'Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp.zo.o., NIP: 527 23 59 887, Lwowska 19, 00 - 660 Warszawa moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu marketingowym podmiotów z grupy Acciona.Wiem, że mam możliwość cofnięcia zgody w każdym czasie.');
            }
            if (this.checked.acceptMailBuildung === false) {
                bodyFormData.set('consent-mailBuildings', '');
            } else {
                bodyFormData.set('consent-mailBuildings', 'Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. zo.o., NIP: 527 23 59 887, Lwowska 19, 00 - 660 Warszawa, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu przesyłania mi ofert handlowych drogą elektroniczną.Wiem, że mam możliwość cofnięcia zgody w każdym czasie.');
            }
            if (this.checked.acceptTelBuildung === false) {
                bodyFormData.set('consent-tel', '');
            } else {
                bodyFormData.set('consent-telBuildings', 'Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp.zo.o., NIP: 527 23 59 887, Lwowska 19, 00 - 660 Warszawa, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celu kontaktu telefonicznego związanego z ofertą handlową.Wiem, że mam możliwość cofnięcia zgody w każdym czasie.');
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            

                axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/buildings', bodyFormData, config).then((response) => {
                    if (response.data == 'ok') {

                        this.$router.push('/thank-you-page')

                        this.infoBox.type = 1
                        this.name = null
                        this.email = null
                        this.message = null
                        this.checkedBuildings = []

                        if (this.locale == 'pl') {
                            this.infoBox.content = 'Email wysłany pomyślnie.'
                        } else {
                            this.infoBox.content = 'Email was sent successfully.'
                        }
                        this.correct = true
                    } else {
                        this.infoBox.type = 2

                        if (this.locale == 'pl') {
                            this.infoBox.content = 'Problem z wysłaniem wiadomości.'
                        } else {
                            this.infoBox.content = 'There is a problem with sending a message.'
                        }
                        this.correct = true
                    }

                }).catch((err) => {
                    this.infoBox.type = 2
                    this.infoBox.content = 'There is a problem with sending a message.'
                    this.correct = true
                    // setTimeout(() => {
                    //     this.infoBox.type = 0
                    // }, 8000)
                    console.log(getErrorMessage(err));
                    //helper to get a displayable message to the user
                    function getErrorMessage(err) {
                        let responseBody;
                        responseBody = err.response;
                        if (!responseBody) {
                            responseBody = err;
                        } else {
                            responseBody = err.response.data || responseBody;
                        }
                        return responseBody.message || JSON.stringify(responseBody);
                    }
                })
            
        },
        onExpired() {
               console.log('Expired')
        },
        getflatList: function () {
            
            const parseString = require("xml2js").parseString;
            axios
                .get(process.env.VUE_APP_VOX)
                .then(res => {
                    const self = this;
                    parseString(res.data, (error, res) => {
                        self.flatListing.xml = res;
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    },
    computed: {
        isActive() { return this.$store.getters.getStatus() },

        filteredList() {
            let result = [];
            result = this.allInvestments.filter(investment => {
                return (
                    investment.level.includes(this.level) &&
                    investment.building.includes(this.building) &&
                    investment.rooms.includes(this.rooms) &&
                    parseInt(investment.area) >= this.value[0] &&
                    parseInt(investment.area) <= this.value[1]
                );
            });

            return result;
        },
        filteredFlats() {
            let result = []
            const selectedBuildings = this.building;
            const selectedFloor = this.floor;
            const selectedRooms = this.rooms;

            if (selectedBuildings === "All" && selectedFloor === "All" && selectedRooms === "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return parseInt(investment.area) >= this.value[0] && parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings !== "All" && selectedFloor === "All" && selectedRooms === "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.building.includes(this.building) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings === "All" && selectedFloor !== "All" && selectedRooms === "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.floor.includes(this.floor) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings === "All" && selectedFloor === "All" && selectedRooms !== "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.rooms.includes(this.rooms) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings !== "All" && selectedFloor !== "All" && selectedRooms === "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.building.includes(this.building) && investment.floor.includes(this.floor) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings !== "All" && selectedFloor === "All" && selectedRooms !== "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.building.includes(this.building) && investment.rooms.includes(this.rooms) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else if (selectedBuildings === "All" && selectedFloor !== "All" && selectedRooms !== "All") {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.floor.includes(this.floor) && investment.rooms.includes(this.rooms) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            } else {
                result = this.flatListing.xml.xml.realestate.filter(investment => {
                    return investment.floor.includes(this.floor) &&
                        investment.building.includes(this.building) &&
                        investment.rooms.includes(this.rooms) &&
                        parseInt(investment.area) >= this.value[0] &&
                        parseInt(investment.area) <= this.value[1]
                })
                return result
            }

        },
        locale() {
            return this.$store.getters.getLocale;
        }
    },
    filters: {
        changeToTitle(value) {
            switch (value) {
                case "1":
                    return "HB 10";
                    break;
                case "2":
                    return "HB 10A";
                    break;
                case "3":
                    return "HB 12";
                    break;
                case "4":
                    return "HB 12A";
                    break;
                case "5":
                    return "HB 14";
                    break;
                case "6":
                    return "HB 14A";
                    break;
            }
        },
        changeLang(value, locale) {
            if (locale == "en") {
                if (value == "Wolne") {
                    return "Free";
                } else if (value == "Rezerwacja") {
                    return "Reserved";
                } else {
                    return "Sold";
                }
            } else if (locale == "ua") {
                if (value == "Wolne") {
                    return "Free";
                } else if (value == "Rezerwacja") {
                    return "Reserved";
                } else {
                    return "Sold";
                }
            } else {
                return value;
            }
        }
    },
    created() {
        if (this.locale != "pl") {
            this.name_translation = "Name";
            this.email_translation = "Email";
            this.message_translation = "Message";
        }
        this.getflatList();
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
}

.transform {
    transform: translateY(-180%) translateX(-50%) !important;
}

.full-search {
    padding: 100px 0;
    background-color: $darkGranat;
    color: #fff;
    margin-top: 100px;

    &__information {

        position: fixed;
        padding: 15px 50px;
        z-index: 1000;
        left: 50%;
        top: 50px;
        transition: transform 0.8s;
        transform: translateX(-50%) translateY(0);
        font-size: 1.2rem;
    }

    &__info-close {
        cursor: pointer;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 14px;
        background-color: transparent;
        border: 0;

        svg {
            width: 100%;
            height: 100%;
            fill: #fff;
        }
    }

    & .custom-container {
        background-color: #efefef;
    }

    &__close {
        cursor: pointer;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: transparent;
        border: 0;

        svg {
            width: 100%;
            height: 100%;
            fill: #fff;
        }
    }

    &__form {
        background-color: $darkGranat;
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        border: 2px solid $red;
        right: 0;
        padding: 2rem 2rem 5rem 2rem;
        z-index: 100;
        transform: translateY(100%);
        transition: transform 0.5s;

        &.opened {
            transform: translateY(0);
        }

        h2 {
            font-size: 1.2rem;
            color: #fff;
            margin-bottom: 2rem;

            span {
                color: #fff;
            }
        }

        div {

            input,
            textarea {
                width: 100%;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #646688;
                padding: 1rem;
                margin-bottom: 0.5rem;
                color: #fff;
                font-size: 1rem;
                resize: none;
            }

            textarea {
                min-height: 8rem;
            }

            input::placeholder,
            textarea::placeholder {
                color: #fff;
            }
        }
    }

    &__chosen {
        width: 70%;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: flex-end;
        z-index: 9999;

        @include breakpoint-max("tablet") {
            width: 100%;
        }

        &-left {
            background-color: $red;
            padding: 1rem;
            font-size: 1.1rem;
            color: #fff;
            transition: transform 0.6s;
            transform: translateY(0);

            &.closed {
                transform: translateY(100%);
            }

            span {
                color: #fff;
            }
        }

        &-right {
            background-color: $red;
            color: #fff;
            padding: 1rem;
            font-size: 1.1rem;
            text-transform: uppercase;
            outline: none;
            border: 0;
            cursor: pointer;
        }
    }

    &__box {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    &__photo {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        background-size: cover;
        background-position: center;
        position: relative;
        text-align: center;

        @include breakpoint-max("tablet") {
            padding-top: 40px;
        }
    }

    &__svg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: auto;
        max-width: 1400px;

        polygon {
            opacity: 0;
            transition: 0.5s ease;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }

        @include breakpoint-max("tablet") {
            padding-top: 40px;
        }
    }

    &__content {
        width: 100%;
        background-color: $darkGranat;

        h2 {

            font-weight: 700;

            span {
                color: $lightBlue;
            }
        }

        &--header {
            padding-bottom: 50px;
            display: flex;
            justify-content: space-between;

            &-left {
                max-width: 60%;

                @include breakpoint-max("mobile") {
                    max-width: 100%;
                }

            }

            &-right {
                display: flex;
                align-items: center;

                span {
                    font-weight: 300 !important;
                }

                &-switch {
                    display: flex;
                    align-items: center;
                    display: none;
                }
            }

            span {
                color: #F1ECE9;
                font-weight: 700;
            }

            h2 {
                font-size: 2.2em;
                font-weight: 400;
            }

            strong {
                color: #fff;
            }

            .switch {
                position: relative;
                display: inline-block;
                width: 88px;
                height: 37px;
                margin: 0 20px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #707070;
                -webkit-transition: .4s;
                transition: .4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 28px;
                width: 28px;
                left: 4px;
                bottom: 4px;
                background-color: #F1ECE9;
                -webkit-transition: .4s;
                transition: .4s;
            }

            input:checked+.slider:before {
                background-color: #FB4240;
            }

            input:focus+.slider:before {
                box-shadow: 0 0 1px #FB4240;
            }

            input:checked+.slider:before {
                transform: translateX(50px);
            }

            /* Rounded sliders */
            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius:
                    50%;
            }

        }
    }

    &__table {
        table {
            border-bottom: 1px solid #646688;
        }

        th {
            background-color: $darkGranat;
            color: #fff;
            text-align: center;
            font-weight: 300;
            border: 1px solid #646688;
            padding: 20px;
        }

        td {
            text-align: center;
            background-color: $darkGranat;
            color: #fff;
            text-align: center;
            font-weight: 300;
            border: none;
            padding: 15px;
            border-right: 1px solid #646688;
            border-left: 1px solid #646688;
        }

        .rezerwacyjna, .Wstrzymane, .ustna {
            color:rgb(255,192,0); 
            font-weight: 700;
        }
        .deweloperska, .przedwstępna {
            color: #fb4240;
            font-weight: 700;
        }

        .Dostępne {
            color: #23bc5b;
            font-weight: 700;
        }

        @include breakpoint-max("tablet") {
            overflow: scroll;
        }

        input[type="checkbox"] {
            margin-right: 5px;
        }

    }

    &__button {
        margin: 50px auto 0 auto;
        width: 238px;
        height: 55px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: $darkGranat;

        }
    }

    &__show {
        @include breakpoint-max("v-large") {
            min-width: 200px;
        }

        a {
            text-decoration: none;
            color: #fff;
            display: block;
            position: relative;

            &:hover {
                opacity: 0.8;
            }

            &::after {
                content: '';
                height: 20px;
                width: 15px;
                background-image: url('~@/assets/download.svg');
                background-size: cover;
                position: absolute;
                top: 3px;
                left: 25px;
                vertical-align: baseline;
                filter: invert(100%) sepia(4%) saturate(121%) hue-rotate(322deg) brightness(118%) contrast(100%);

            }

        }
    }

    &__filters {
        padding: 48px;
        background-color: #fff;

        h2 {
            color: $granat;
            font-size: 28px;
            font-weight: bold;
            line-height: 38px;
            padding-bottom: 20px;

            span {
                color: $lightBlue;
            }
        }

        @include breakpoint-max("tablet") {
            padding: 20px;
        }
    }

    &__yardage {
        border: 1px solid $lightGray;
        padding: 10px;
        margin: 10px 0;
        display: flex;

        @include breakpoint-max("tablet") {
            margin: 10px 0;
        }

        span,
        h3 {
            flex: 1;
        }

        h3 {
            @include breakpoint-max("mobile") {
                font-size: 12px;
            }
        }
    }

    &__search-btn {
        width: 210px;
        padding: 14px;
        background-color: $lightBlue;
        border: none;
        margin-top: 10px;
        text-transform: uppercase;
        color: #fff;
        font-size: 12px;
        letter-spacing: 1px;
        cursor: pointer;
    }

    &__slider {
        flex: 10;
        padding-left: 18px;

        @include breakpoint-max("tablet") {
            flex: 6;
        }

        @include breakpoint-max("mobile") {
            flex: 2;
        }
    }

    &__value2 {
        flex: 1;
        text-align: right;
    }

    &__left-value {
        @include breakpoint-max("tablet") {
            margin-left: 20px;
        }
    }

    &__right-value {
        margin-left: 20px;
    }

    &__info {
        width: 100%;
        padding: 15px 20px;
        font-size: 1.4rem;
        color: #fff;
        background-color: $lightBlue;
        text-align: center;

        @include breakpoint-max("tablet") {
            padding-top: 55px;
        }
    }

    &__inputs {
        display: flex;
        flex-direction: column;

        @include breakpoint-max("tablet") {
            flex-direction: column;
        }

        select {
            flex: 1;
            width: 100%;
            outline: none;
            border: 1px solid $lightGray;
            border-radius: none;
            margin: 10px 0;
            font-size: 14px;
            appearance: none;
            border-radius: 0;
            padding: 10px;
            color: #4a4a4a;
            position: relative;

            // &::after {
            //     display: block;
            //     content: "→";
            //     background-color: red;
            //     width: 20px;
            //     height: 20px;
            //     position: absolute;
            //     color: $lightBlue;
            //     font-size: 18px;
            //     z-index: 999999;
            //     pointer-events: none;
            // }
            // &:first-child{
            //     // margin-right: 10px;
            //     @include breakpoint-max('tablet'){
            //         margin: 10px 0;
            //     }
            // }
            // &:last-child{
            //     margin-left: 10px;
            //     @include breakpoint-max('tablet'){
            //         margin: 10px 0;
            //     }
            // }
            @include breakpoint-max("tablet") {
                width: 100%;
            }
        }
    }

    .contact__check {
        display: flex;
        font-size: 12px;
        align-items: center;

        input {
            flex: 1;
        }

        label {
            flex: 95%;
        }

    }
}
</style>
