<template>
  <section class="section post-list is-paddingless" :class="className">
    <div class="post-list__all">
      <div class="custom-container">
        <div class="post-list__all--title">
          <h1 v-if="locale === 'pl'">Dziennik budowy</h1>
          <h1  v-if="locale === 'en'">Construction Log</h1>
        </div>
        <div v-if="locale === 'pl'" class="post-list__all--flex">
          <div
            class="post-list__all--wrap"
            v-for="(item, key) in posts.slice(0, 3)"
            v-if="item.category[0].slug === 'dziennik'" >
        
            <img :src="item.thumbnail" v-if="item.thumbnail" />

            <h2 v-if="item.title" v-html="item.title"></h2>

            <a :href="item.link" @click.prevent="$router.push(item.link)"
              >Zobacz</a>
          </div>
        </div>
        <div v-if="locale === 'en'" class="post-list__all--flex">
          <div
            class="post-list__all--wrap"
            v-for="(item, key) in posts.slice(0, 3)"
            v-if="item.category[0].slug === 'construction'" >
        
            <img :src="item.thumbnail" v-if="item.thumbnail" />

            <h2 v-if="item.title" v-html="item.title"></h2>

            <a :href="item.link"
              >More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "logbuilding",
  data() {
    return {};
  },
  methods: {
    shorter(str) {
      return str.slice(0, 247) + "...";
    }
  },
  computed: {
    posts() {
      return this.$store.getters.getPosts;
    },
    postsPage() {
      return;
      this.$store.getters.getOptionsPostsPage;
    },
    locale() {
      return this.$store.getters.getLocale;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.test {
  position: relative;
}

.post-list {
  &__all {
    margin: auto;
    padding-top: 60px;
    background-color: $secondBackgroundColor;

    @include mobile {
      padding: 10px !important;
      padding-top: 30px !important;
    }

    &--title {
      font-size: 2.2em;
      margin-bottom: 50px;
    }

    &--flex {
      display: flex;
      flex-wrap: wrap;
    }

    &--wrap {
      padding: 50px;
      width: 33%;

      @include breakpoint-max("tablet") {
        width: 50%;
      }

      @include breakpoint-max("mobile") {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
      img {
        height: 350px;
        object-fit: cover;
      }
      h2 {
        font-size: 1.5em;
        margin: 25px 0;
      }

      a {
        position: relative;
        text-align: left;

        @include breakpoint-max("mobile") {
          margin-right: 50px;
        }

        &::after {
          content: "";
          height: 15px;
          width: 15px;
          background-image: url("~@/assets/arrow-right.svg");
          background-size: cover;
          position: absolute;
          top: 6px;
          right: -30px;
          filter: invert(13%) sepia(19%) saturate(1015%) hue-rotate(200deg)
            brightness(100%) contrast(92%);
        }

        &:hover {
          color: $red;
        }
      }
    }
  }

  @include touch {
    padding-top: 80px;
    margin: 0 40px;
  }

  @include mobile {
    padding-top: 40px;
    margin: 0 10px;
  }
}

.subtitle {
  font-weight: bold !important;
  font-style: normal !important;
  font-size: 20px !important;
  color: $granat !important;
}

.arrow-text {
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    height: 14px;
    width: 14px;
  }

  a {
    text-transform: uppercase;
    font-size: 14px;
    color: $granat;
  }
}

.content-wrapper {
  h1 {
    cursor: pointer;
  }

  @include mobile {
    margin: 30px 0 !important;
  }
}

.white-block {
  height: 180px;
  width: 100%;
  background-color: #f5f5f5;
  position: absolute;
  bottom: -1px;
}

// .image-wrapper {
//     figure {
//         height: 100%;
//         width: 100%;
//         img {
//             height: 100%;
//             object-fit: cover;
//         }
//     }
// }
.margin-top {
  margin-top: 18px;
}
</style>
