<template>
<section class="intro" :class="[data.custom_class]">
    <div class="intro__container" :class="[data.custom_container]">
        <div class="intro__image-box" :style="{order: data.order_image}">
            <div class="intro__map-box" data-aos="fade-left">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9780.899682173578!2d20.8569281!3d52.20296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd8f71d512f82ef31!2sU%20City%20Residence%20Ursus!5e0!3m2!1spl!2spl!4v1630323691548!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <!-- <gmap-map :center="{lat: 52.202194, lng: 20.855613}" :zoom="14" :options="mapStyle" class="intro__iframe">
                    <GmapMarker :key="index" v-for="(m, index) in data.repeater" :icon="m.icon.url" :position="{lat: replace(m.lat), lng: replace(m.lng)}" @mouseover="openInfoWindowTemplate(m.lat, m.lng, m.title)" @mouseout="infoWindow.open = false" @click="openOverlay(m.title, m.address, m.description)" />
                    <gmap-info-window :options="{maxWidth: 300}" :position="infoWindow.position" :opened="infoWindow.open">
                        <div>
                            <h2>{{ infoWindow.title }}</h2>
                        </div>
                    </gmap-info-window>
                </gmap-map> -->
            </div>
        </div>
        <div class="intro__text-box">
            <h1 v-if="locale === 'pl'" data-aos="fade-right">Kontakt</h1>
            <h1 v-if="locale === 'en'" data-aos="fade-right">Contact</h1>
            <div class="intro__text" data-aos="fade-right">
                
                <div class="intro__wrapper">
                    <span v-if="locale === 'pl'">BIURO SPRZEDAŻY U-CITY WARSZAWA</span>
                    <span v-if="locale === 'en'">Sales office located on the premise</span>
                    <p class="intro__text--map">ul. Henryka Brodatego 14 m 2, 02-496 Warszawa</p>
                </div>
                <div class="intro__wrapper">
                    <a class="intro__text--tel" href="tel:+48 22 402 25 95">+48 22 402 25 95</a>
                </div>
                <div class="intro__wrapper">
                    <span v-if="locale === 'pl'">Godziny pracy biura</span>
                    <span v-if="locale === 'en'">Office hours</span>
                    <p v-if="locale === 'pl'" class="intro__text--clock">pon. - pt. 12:00 - 18:00<br /> Kontakt telefoniczny 10:00 - 18:00 <br/> Wizyty w biurze sprzedaży są możliwe po wcześniejszym umówieniu.</p>
                    <p v-if="locale === 'en'" class="intro__text--clock">Monday-Friday 10.00-18.00<br />Saturday 10.00-14.00</p>
                </div>
            </div>
            <div class="intro__wrapper">
                <div class="intro__social-nav">
                    <a target="_blank" href="https://www.facebook.com/accionanieruchomosci/">FACEBOOK</a>
                    <a target="_blank" href="https://www.instagram.com/accionanieruchomosci/">INSTAGRAM</a>
                </div>
            </div>

        </div>

    </div>
</section>
</template>

<script>
const mapStyle = [{
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [{ "weight": "1.00" }]
    }, {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [{ "color": "#9c9c9c" }]
    }, {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [{ "visibility": "on" }]
    }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{ "color": "#f2f2f2" }]
    }, {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#ffffff" }]
    }, {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#dddddd" }]
    },
    { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{ "saturation": -100 }, { "lightness": 45 }]
    }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#eeeeee" }]
    }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#7b7b7b" }]
    }, {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [{ "color": "#ffffff" }]
    }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{ "visibility": "simplified" }]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "all" }]
    }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "on" }] }, {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }]
    }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#c8d7d4" }]
    }, {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#070707" }]
    }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }
]

export default {
    name: "Intro",
    data() {
        return {
            mapStyle: { styles: mapStyle },
            infoWindow: {
                position: { lat: 0, lng: 0 },
                open: false,
                title: ''
            },
            overlay: {
                toggle: false,
                title: '',
                description: '',
                address: ''
            }
        }
    },
    methods: {
        replace(p) {
            return parseFloat(p.replace(',', '.').replace(' ', ''))
        },
        openInfoWindowTemplate(lat, lng, title) {
            this.infoWindow.position = { lat: this.replace(lat) + 0.0026, lng: this.replace(lng) }
            this.infoWindow.open = true
            this.infoWindow.title = title
        },
        openOverlay(title, address, description) {
            this.overlay.toggle = true
            this.overlay.title = title
            this.overlay.address = address
            this.overlay.description = description
        },
    },
    computed: {
        locale() {
            return this.$store.getters.getLocale;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.intro {
    display: flex;

    &__container {
        display: flex;
        position: relative;

        @include breakpoint-max("s-tablet") {
            flex-direction: column;
            padding-bottom: 90px;
        }
    }

    &__additional-text {
        position: absolute;
        bottom: -60px;
        color: #5867a1;
        font-size: 12px;
    }

    &__image-box {
        display: flex;
    }

    &__image-box {
        min-height: 400px;
        flex: 14;
        background-position: center;
        background-size: cover;

        @include breakpoint-max("s-tablet") {
            min-height: 400px;
            background-position-y: bottom;
        }

        @include breakpoint-max("xs-tablet") {
            min-height: 360px;
        }

        @include breakpoint-max("mobile") {
            min-height: 300px;
        }

    }

    &__text-box {
        background-color: $secondBackgroundColor;
        min-width: 600px;
        max-width: 600px;

        @include breakpoint-max("tablet") {
            min-width: 50%;
            max-width: 50%;

        }

        @include breakpoint-max("s-tablet") {
            min-width: 100%;
            max-width: 100%;
        }

        h1 {
            font-size: 2.2em;
            font-weight: 400;
            margin: 30px 0 50px 0;
            line-height: 1.25;

            @include breakpoint-max("s-tablet") {
                font-size: 36px;
            }

            @include breakpoint-max("mobile") {
                font-size: 30px;
            }
        }
    }

    &__wrapper {
        margin-bottom: 50px;

        @include breakpoint-max("s-tablet") {
            margin-bottom: 10px;
        }

    }

    &__social-nav {
        margin-top: 70px;

        a {
            margin-right: 30px;
        }
    }

    &__text {
        margin-left: 40px;

        a {
            display: inline-flex;
            font-size: 1.4em;

            @include breakpoint-max("mobile") {
                font-size: 1.2em;
            }

        }

        span {
            font-size: 1em;
        }

        p {
            font-size: 1.4em;

            @include breakpoint-max("mobile") {
                font-size: 1.2em;
            }
        }

        &--tel {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                height: 26px;
                width: 26px;
                background-image:
                    url('~@/assets/phone-new.svg');
                background-size: cover;
                position: absolute;
                top: 7px;
                left: -40px;
                filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
            }
        }

        &--mail {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                height: 26px;
                width: 26px;
                background-image: url('~@/assets/mail.svg');
                background-size: cover;
                position: absolute;
                top: 7px;
                left: -40px;
                filter:
                    invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
            }
        }

        &--map {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                height: 30px;
                width: 26px;
                background-image: url('~@/assets/marker-mapa.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 2px;
                left: -40px;
                filter:
                    invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
            }
        }

        &--clock {
            position: relative;
            margin-bottom: 20px;

            &::before {
                content: '';
                height: 30px;
                width: 26px;
                background-image: url('~@/assets/zegar.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 7px;
                left: -40px;
                filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg) brightness(96%) contrast(104%);
            }
        }

    }

    &__map-box {
        height: 100%;
        width: 100%;

    }

    &__googlemap {
        width: 100%;
        height: 800px;
        position: relative;
    }

    & iframe {
        width: 100%;
        height: 100%;
        min-height: 800px;

        @include breakpoint-max("s-tablet") {
            min-height: 400px;

        }

    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $orange;
        z-index: 800;
        color: #000;

        &--margin {
            margin: 20px;

            h2 {
                font-family: 'Gilroy-bold';
            }

            hr {
                background-color: #000;
                height: 1px;
            }
        }

        img {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
}
</style>
