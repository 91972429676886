<template>
<section class="central-container" :class="[data.custom_class]">
    <div class="central-container__container custom-container">

        <div class="central-container__text-box">
            <span class="central-container__subheading" data-aos="fade-left">{{data.subtitle}}</span>
            <h2 class="central-container__heading" data-aos="fade-left">
                {{ data.title }}
            </h2>
            <div v-html="data.text" class="central-container__text" data-aos="fade-left"></div>
            <router-link v-if="data.href" class="central-container__link" :to="data.href" data-aos="fade-left">{{ data.link_text }}</router-link>
        </div>

        <div class="central-container__image-box">
            <img :src="data.image.url">
            <!-- <div class="central-container__image-box--after">
                <div class="central-container__image-box--after-image">
                    <img :src="data.image_logo.url">
                </div>
                <p v-html="data.text_center"></p>
            </div> -->
        </div>
        <div class="central-container__text-box--right">
            <div class="central-container__text-box--right-p" v-html="data.text_right" data-aos="fade-right">
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "CentralContainer",
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

//custom classes for modify default component layout
.extra-padding-y {
    padding: 100px 0 50px 0;

    @include breakpoint-max("xs-tablet") {
        padding: 250px 0 0 0;

    }

    @include breakpoint-max("mobile") {
        padding: 350px 0 0 0;
    }

}

.overflow-hidden {
    overflow: hidden;
}

.central-container {
    display: flex;
    position: relative;
    z-index: 800;

    &__container {
        display: flex;

        @include breakpoint-max("xs-tablet") {
            flex-direction: column;
        }
    }

    &__text-box {
        font-size: 18px;
        font-weight: 300;
        flex: 33%;

        span {
            font-weight: 700;
        }

        h2 {
            font-size: 2.2em;
            line-height: 1em;
            margin: 20px 0 30px 0;
        }

        &::before {
            content: '';
            display: block;
            height: 70px;
            width: 50px;
            border-bottom: 2px solid $darkGranat;
            position: absolute;
            left: -80px;
        }

        @include breakpoint-max("tablet") {}

        @include breakpoint-max("s-tablet") {}

        @include breakpoint-max("mobile") {}
    }

    &__text-box--right {
        flex: 33%;
        margin-top: 80px;

        &-p {
            p {
                margin-top: 20px;
            }
        }
    }

    &__image-box {
        flex: 33%;
        margin: 0 60px;
        position: relative;

        @include breakpoint-max("xs-tablet") {
            margin-top: 50px;
        }

        /* &::after {
            content: '';
            height: 150px;
            width: 150px;
            background-image: url('~@/assets/ucity-des-pl.svg');
            background-size: cover;
            position: absolute;
            top: -60px;
            right: -60px;
        } */

        &--after {
            display: flex;

            &-image {
                min-width: 268px;
                height: 268px;
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                margin-top: -80px;
                margin-left: -50px;
                margin-right: 30px;
                z-index: 999;
                position: relative;

                @include breakpoint-max("mobile") {
                    min-width: auto;
                    height: 100px;
                }

                img {
                    height: auto;
                    width: 60%;
                }
            }

            p {
                flex: 1;
                margin-top: 50px;
            }
        }

        @include breakpoint-max("xs-tablet") {}
    }

    &__text {
        color: $darkGranat;
    }

    &__link {
        margin-top: 30px;
        letter-spacing: 1.25px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 200px;
        height: 55px;
        background: $darkGranat;
        color: #fff;

    }
}
</style>
