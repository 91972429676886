<template>
    <section class="thank-you-page">
        <div class="svg-box1">
            <svg id="Warstwa_1" data-name="Warstwa 1" class="svg1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.4 726.92"><defs></defs><path class="cls-1" d="M94.56,0V375.38A117.46,117.46,0,0,1,0,490.58V726.92c183.89-11.78,329.4-164.67,329.4-351.54V0Z"/></svg>
        </div>
        <div class="thank-you-page__box">
            <h1>{{data.title}}</h1>
            <p v-html="data.content">{{data.content}}</p>
        </div>
        <div class="svg-box2">
            <svg id="Warstwa_1" data-name="Warstwa 1" class="svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 329.45 923.92"><defs></defs><path class="cls-2" d="M329.4,687.58a117.46,117.46,0,0,1-94.56-115.2V0H0V572.38C0,759.27,145.53,912.16,329.45,923.92,329.4,924.65,329.4,687.65,329.4,687.58Z"/></svg>
        </div>
    </section>
</template>

<script>
export default {
    name: "ThankYouPage",
    data() {
        return {}
    },
    methods:{
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

//custom classes for modify default component layout
.svg-box1 {
    position: absolute;
    top: 0;
    left: 0;
    @include tablet-only {
        height: 600px;
        left: -36px;
    }
    @include mobile {
        // height: 350px;
        // left: -70px;
        display: none;
    }
}
.svg-box2 {
    position: absolute;
    top: 0;
    right: 0;
    @include tablet-only {
        height: 600px;
        right: -62px;
    }
    @include mobile {
        // height: 350px;
        // right: -86px;
        display: none;
    }
}
.thank-you-page {
    position: relative;
    height: 70vh;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        position: relative;
        .cls-1, .cls-2{fill:#e4e4e2;}
        .cls-1 {
            position: absolute;
            left: 0;
        }
        .cls-2 {
            position: absolute;
            right: 0;
        }
    }
    @include mobile {
        height: 100%;
    }
    &__background {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left:0;
        z-index: 1;
    }
    &__box {
        max-width: 1200px;
        padding: 0 60px;
        text-align: center;
        z-index: 10;
        @include mobile {
            padding: 100px 30px;
        }
        /deep/ h1 {
            margin-bottom: 30px;
            font-weight: bold;
            color: $granat;
            font-size: 26px;
        }
        /deep/ p {
            font-size: 24px;
            color: $granat;
        }
    }
}
.svg1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.svg2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

</style>
