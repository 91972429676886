<template>
<section class="faq">
    <div class="faq__main custom-container">
        <h1 v-html="data.title"></h1>
        <div class="faq__main--wrapper">
            <vsa-list>
                <div class="faq__main--wrap" v-for="(item, index) in data.faq_wrapper" :key="index">

                    <vsa-item>
                        <vsa-heading>
                            <span class="faq__question" v-if="item.question" v-html="item.question"></span>
                        </vsa-heading>

                        <vsa-content>
                            <p v-if="item.answer" v-html="item.answer"></p>
                        </vsa-content>
                    </vsa-item>

                </div>
            </vsa-list>
        </div>

    </div>
</section>
</template>

<script>
import { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon } from 'vue-simple-accordion';

export default {
    name: "FAQ",
    data() {
        return {};
    },
    components: { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon }

};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.faq {
    padding: 50px 0 100px 0;

    @include breakpoint-max("mobile") {
        padding: 20px 0;
    }

    &__main {
        h1 {
            font-size: 2.2em;
            font-weight: 300;
            margin-bottom: 60px;
        }

        &--wrapper {
            &:last-child {
                border-bottom: 2px solid lightgrey;

            }

            h2 {
                font-size: 1.1em;
                cursor: pointer;
                position: relative;
            }

        }

        &--wrap {
            border-top: 2px solid lightgrey;

            padding: 40px 0;

        }

    }
}

/deep/ .vsa-item__trigger {
    background-color: $secondBackgroundColor;
    border: none;
    color: $darkGray;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;

    &::after {
        content: '';
        height: 12px;
        width: 14px;
        background-image:
            url('~@/assets/arrow-down.svg');
        background-size: contain;
        background-repeat:
            no-repeat;
        position: absolute;
        top: 7px;
        right: 20px;
        filter: invert(15%) sepia(27%) saturate(623%) hue-rotate(197deg) brightness(92%) contrast(94%);

        @include breakpoint-max("mobile") {
            right: -10px;
        }

    }

}

/deep/ .vsa-item__content {
    margin-top: 40px;
}

/deep/ .vsa-item--is-active {
    .vsa-item__trigger {
        font-weight: 700;

        &::after {
            background-image: url('~@/assets/arrow-up.svg');

        }

    }

}
</style>
