<template>
<section class="contact">
    <div class="popup" v-if="showPopup">
        <div class="popup__close" @click="showPopup = false"></div>
        <div class="popup__text" v-html="footer_pl.polytics" v-if="locale == 'pl'"></div>
        <div class="popup__text" v-html="footer_ua.polytics" v-else-if="locale == 'ua'"></div>
        <div class="popup__text" v-html="footer_en.polytics" v-else></div>
    </div>
    <div class="custom-container contact__flex contact__lower" v-if="locale == 'pl'">
        <div class="contact__left">
            <h2>Zostaw numer telefonu,<br /> oddzwonimy do Ciebie</h2>
            <p>Masz pytania? Nasi doradcy odpowiedzą na Twoje pytania i pomogą wybrać idealne mieszkanie.</p>
            <form @submit.prevent="onSubmit">
                <div class="contact__form">
                    <div class="contact__left-form">
                        <input type="text" :placeholder="footer_pl.right.name" id="name" v-model="name" required>
                        <input type="email" :placeholder="footer_pl.right.email" id="email" v-model="email" required>
                        <input type="text" :placeholder="footer_pl.right.phone" id="phone" v-model="phone" required>
                    </div>

                </div>
                <div class="contact__checkbox contact__flex">
                    <p>Brak udzielenia poniższych zgód uniemożliwi nam przesłanie kompleksowych informacji o inwestycjach wskazanych spółek z grupy Acciona. Natomiast kontakt z naszej strony będzie ograniczony do obsługi Twojego zapytania. </p>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-marketing" name="consent-marketing" v-model="checked.acceptMarketing">
                        <label for="consent-marketing">Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. z o.o., z siedzibą w Warszawie (00-660) przy ul. Lwowskiej 19, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celach marketingowych podmiotów z grupy Acciona.
                        </label>
                    </div>
                    <p>Preferowana forma kontaktu:</p>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-tel" name="consent-tel" v-model="checked.acceptTel">
                        <label for="consent-tel">telefon</label>

                    </div>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-mail" name="consent-mail" v-model="checked.acceptMail">
                        <label for="consent-mail">email</label>
                    </div>
                    <div class="contact__check">
                        <input type="checkbox" id="consent-sms" name="consent-sms" v-model="checked.acceptSms">
                        <label for="consent-sms">SMS</label>
                    </div>
                    <p>W <a href="https://acciona-nieruchomosci.pl/polityka-prywatnosci/" rel="noopener" target="_blank">Polityce Prywatności </a>znajdziesz informacje o zasadach przetwarzania danych osobowych. Masz możliwość cofnięcia ww. zgód w każdym czasie.</p>
                    <p>Pola oznaczone * są polami obowiązkowymi.</p>

                    <input type="submit" class="contact__btn" id="submit" value="Proszę o kontakt">
                </div>
                <vue-recaptcha 
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    sitekey="6LdMu_0cAAAAAAH2-oGHEAvTTY5vadLXEw3uaieF" 
                    >
                </vue-recaptcha>
            </form>
            <transition name="fade" mode="out-in">
                <article class="message" :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}" v-if="infoBox.type != 0" @click="infoBox.type = 0">
                    <div class="message-header">
                        <p>{{ infoBox.type == 1 ? 'Sukces' : 'Error' }}</p>
                        <button class="delete" aria-label="delete"></button>
                    </div>
                    <div class="message-body">
                        {{ infoBox.content }}
                    </div>
                </article>
            </transition>
        </div>
        <div class="contact__left contact__left-margin">
            <h2>Kontakt</h2>
            
            <p class="contact__left-bold">ACCIONA Nieruchomości</p>
            <ul>
                <li>ul. Lwowska 19</li>
                <li>00-660 Warszawa</li>
                <li><a href="tel:+48224022595">+48 22 402 25 95</a></li>
                <li><a href="https://acciona-nieruchomosci.pl/" target="_blank" rel="noopener">acciona-nieruchomosci.pl</a></li>
            </ul>
            <div class="contact__left-developer">
                <a href="https://acciona-nieruchomosci.pl/" target="_blank"><img class="contact__logo" src="@/assets/Acciona-LOGO.png" alt="Acciona nieruchomości"></a>
                <p>{{ footer_pl.left.header }}</p>
            </div>
        </div>
        
        <div class="contact__left contact__left-margin">
            <p id="ct_headline_80_post_3599" class="contact__left-bold">BIURO SPRZEDAŻY U-CITY WARSZAWA
</p>

            <div id="ct_paragraph_44_post_3877" class="ct-paragraph footer-text">
                <ul>
                    <li>ul. Henryka Brodatego 14 lok.2, 02-496 Warszawa</li>
                    <li>Godziny pracy Biura<br />
                        pon. - pt. 12:00 - 18:00<br />Kontakt telefoniczny 10:00 - 18:00<br />Wizyty w biurze sprzedaży są możliwe po wcześniejszym umówieniu.</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="custom-container contact__flex" v-if="locale == 'en'">
        <div class="contact__left">
            <h2>{{ footer_en.left.header }}</h2>
            <div v-html="footer_en.left.text"></div>
        </div>
        <div class="contact__right">
            <h2>Contact form</h2>
            <form @submit.prevent="onSubmit">
                <div class="contact__form">
                    <div class="contact__left-form">
                        <input type="text" required :placeholder="footer_en.right.name" id="name" v-model="name">
                        <input type="email" required :placeholder="footer_en.right.email" id="email" v-model="email">
                        <input type="text" :placeholder="footer_en.right.phone" id="phone" v-model="phone">
                    </div>
                </div>
                <div class="contact__checkbox contact__flex">
                    <div class="contact__check">
                        <input type="checkbox" id="check" name="checkbox-message" required>
                        <label for="check">I have read and accept <a href="#" @click.prevent="showPopup = true">the information regarding the personal data controller and processing</a></label>
                    </div>
                    <input type="submit" class="contact__btn" id="submit" :value="sendLabel">
                </div>
                <a href="https://acciona-nieruchomosci.pl/" target="_blank"><img class="contact__logo" src="@/assets/Acciona-LOGO.png" alt="Acciona nieruchomości"></a>
                <vue-recaptcha 
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    size="invisible"
                    sitekey="6LdMu_0cAAAAAAH2-oGHEAvTTY5vadLXEw3uaieF" 
                    >
                </vue-recaptcha>
            </form>
            <transition name="fade" mode="out-in">
                <article class="message" :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}" v-if="infoBox.type != 0" @click="infoBox.type = 0">
                    <div class="message-header">
                        <p>{{ infoBox.type == 1 ? 'Sukces' : 'Error' }}</p>
                        <button class="delete" aria-label="delete"></button>
                    </div>
                    <div class="message-body">
                        {{ infoBox.content }}
                    </div>
                </article>
            </transition>
        </div>
    </div>
    <div class="custom-container contact__flex" v-if="locale == 'ua'">
        <div class="contact__left">
            <h2>{{ footer_ua.left.header }}</h2>
            <div v-html="footer_ua.left.text"></div>
        </div>
        <div class="contact__right">
            <h2>Контактна форма</h2>
            <form @submit.prevent="onSubmit">
                <div class="contact__form">
                    <div class="contact__left-form">
                        <input type="text" required :placeholder="footer_ua.right.name" id="name" v-model="name">
                        <input type="email" required :placeholder="footer_ua.right.email" id="email" v-model="email">
                        <input type="text" :placeholder="footer_ua.right.phone" id="phone" v-model="phone">
                    </div>

                </div>
                <div class="contact__checkbox contact__flex">
                    <div class="contact__check">
                        <input type="checkbox" id="check" name="checkbox-message" required>
                        <label for="check">Я ознайомився та приймаю <a href="#" @click.prevent="showPopup = true">інформацію про розпорядника й обробку моїх персональних даних.</a></label>
                    </div>
                    <input type="submit" class="contact__btn" id="submit" :value="sendLabel">
                </div>
                <a href="https://acciona-nieruchomosci.pl/" target="_blank"><img class="contact__logo" src="@/assets/Acciona-LOGO.png" alt="Acciona nieruchomości"></a>
                <vue-recaptcha 
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :loadRecaptchaScript="true"
                    sitekey="6LdMu_0cAAAAAAH2-oGHEAvTTY5vadLXEw3uaieF" 
                    >
                </vue-recaptcha>
            </form>
            <transition name="fade" mode="out-in">
                <article class="message" :class="{'is-success':infoBox.type == 1,'is-danger':infoBox.type == 2}" v-if="infoBox.type != 0" @click="infoBox.type = 0">
                    <div class="message-header">
                        <p>{{ infoBox.type == 1 ? 'Sukces' : 'Error' }}</p>
                        <button class="delete" aria-label="delete"></button>
                    </div>
                    <div class="message-body">
                        {{ infoBox.content }}
                    </div>
                </article>
            </transition>
        </div>
    </div>
</section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import axios from 'axios'
export default {
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    data() {
        return {
            showPopup: false,
            name: '',
            email: '',
            phone: '',
            sms: '',
            checked: {
                acceptMarketing: false,
                acceptMail: false,
                acceptTel: false,
                acceptSms: false,
            },
            sendLabel: 'Wyślij',
            infoBox: {
                type: 0, // 0 - no, 1 - success, 2 - error
                content: ''
            },
            // sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    computed: {
        footer_pl() {
            return this.$store.getters.getFooterPl
        },
        footer_en() {
            return this.$store.getters.getFooterEn
        },
        footer_ua() {
            return this.$store.getters.getFooterUa
        },
        locale() {
            return this.$store.getters.getLocale;
        },
    },
    methods: {
        onSubmit() {
            
            this.$refs.recaptcha.execute()
            if (this.locale == 'en') {
                this.sendLabel = 'Sending...'
            } else if (this.locale == 'pl') {
                this.sendLabel = 'Wysyłanie...'
            } else {
                this.sendLabel = 'Надіслати'
            }
        },

            onVerify(recaptchaToken) {
            this.$refs.recaptcha.reset();
            var bodyFormData = new FormData();
            bodyFormData.set('name', this.name);
            bodyFormData.set('email', this.email);
            bodyFormData.set('phone', this.phone);
            bodyFormData.set('sms', this.sms);

            if (this.checked.acceptMarketing === false) {
                bodyFormData.set('consent-marketing', '');
            } 
            else {
                bodyFormData.set('consent-marketing', 'Wyrażam zgodę na przetwarzanie przez TOWAROWA PARK sp. z o. o. z siedzibą w Warszawie (00-660) przy ul. Lwowskiej 19, moich danych osobowych zawartych w niniejszym formularzu kontaktowym w celach marketingowych podmiotów z grupy Acciona.');

            }
            
            if (this.checked.acceptMail === false) {
                bodyFormData.set('consent-mail', '');
            }

            else {
                bodyFormData.set('consent-mail', 'email');

            }

            if (this.checked.acceptTel === false) {
                bodyFormData.set('consent-tel', '');

            } 
            
            else {
                
                bodyFormData.set('consent-tel', 'telefon');

            }

            if (this.checked.acceptSms === false) {
                bodyFormData.set('consent-sms', '');

            } 
            
            else {
                
                bodyFormData.set('consent-sms', 'SMS');

                }

            bodyFormData.set('recaptchaToken', recaptchaToken);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                axios.post(process.env.VUE_APP_API + 'wp-json/better-api/v1/send', bodyFormData, config).then((response) => {
                    if (response.data == 'ok') {
                        this.$router.push('/thank-you-page')

                        this.infoBox.type = 1
                        this.infoBox.content = 'Email wysłany pomyślnie.'

                        if (this.locale == 'en') {
                            this.sendLabel = 'Send'
                        } else if (this.locale == 'pl') {
                            this.sendLabel = 'Wyślij'
                        } else {
                            this.sendLabel = 'Надіслати'
                        }

                        // ga('send', 'event', 'Contact', 'Form', 'Success')
                        // gtag('event', 'Form', {
                        //     'event_category': 'Contact',
                        //     'event_label': 'Success',
                        // })
                        // setTimeout(() => {
                        //     this.infoBox.type = 0
                        // },8000)
                    } else {
                        this.infoBox.type = 2
                        this.infoBox.content = 'Problem z wysłaniem wiadomości.'
                        if (this.locale == 'en') {
                            this.sendLabel = 'Send'
                        } else if (this.locale == 'pl') {
                            this.sendLabel = 'Wyślij'
                        } else {
                            this.sendLabel = 'Надіслати'
                        }
                        // setTimeout(() => {
                        //     this.infoBox.type = 0
                        // },8000)
                    }

                }).catch(() => {
                    this.infoBox.type = 2
                    this.infoBox.content = 'Nie można wysłać wiadomości. Spróbuj ponownie później'
                    if (this.locale == 'en') {
                        this.sendLabel = 'Send'
                    } else if (this.locale == 'pl') {
                        this.sendLabel = 'Wyślij'
                    } else {
                        this.sendLabel = 'Надіслати'
                    }
                    // setTimeout(() => {
                    //     this.infoBox.type = 0
                    // }, 8000)
                    //helper to get a displayable message to the user
                    // function getErrorMessage(err) {
                    //     let responseBody;
                    //     responseBody = err.response;
                    //     if (!responseBody) {
                    //         responseBody = err;
                    //     } else {
                    //         responseBody = err.response.data || responseBody;
                    //     }
                    //     return responseBody.message || JSON.stringify(responseBody);
                    // }
                })
            },
           onExpired() {
               console.log('Expired')
           }
        },
        mounted() {
            const polytics = document.querySelector('#polytics')
            polytics.addEventListener('click', (e) => {
                e.preventDefault()
                this.showPopup = true
            })
        },
        created() {
            if (this.locale == 'en') {
                this.sendLabel = 'Send'
            } else if (this.locale == 'pl') {
                this.sendLabel = 'Wyślij'
            } else {
                this.sendLabel = 'Надіслати'
            }
        }
    }
</script>

<style lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.grecaptcha-badge {
    visibility: visible;
    left: 0 !important;
} 

.contact {
    background-color: $secondBackgroundColor;
    padding: 100px 0;
    box-shadow: inset 0px 4px 5px -4px rgba(0, 0, 0, 0.3);

    @include breakpoint-max ('mobile') {
        padding: 20px 0;
    }

    &__flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__lower {
        margin-top: 50px !important;
    }

    &__cform {
        margin-bottom: 40px !important;
    }

    &__left,
    &__right {
        h2 {
            font-size: 2.2em;
            font-weight: 400;
            ;
            margin-bottom: 20px;
            color: $darkGranat;
            line-height: 1.2em;
        }
    }

    &__left {

        :nth-of-type(1) {
            max-width: 420px;
        }

        &-bold {
            color: $darkGranat;
            font-weight: 700;
        }

        &-margin {
            margin-top: 50px;
            min-width: 340px;

            #ct_headline_80_post_3599 {
                margin-top: 65px;
            }
        }

        &-developer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpoint-max ('mobile') {
                flex-direction: column;
                justify-content: flex-start;
            }

            p {
                margin-top: 20px;
                font-weight: 700;
                color: $darkGranat;
                font-size: 1.1em;
            }
        }

        @include breakpoint-max('s-tablet') {
            flex: 100%;
            margin-bottom: 50px;

        }

        @include breakpoint-max ('mobile') {
            margin-bottom: 0;
            margin-top: 20px;
        }

        ul {
            list-style: none;
            color: $darkGray;
            margin-top: 10px;
            line-height: 30px;
            font-weight: 300;

            li {
                a {
                    text-decoration: none;
                    color: $darkGray;
                }
            }
        }
    }

    &__center {
        flex: 12;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
    }

    &__left-form {
        display: flex;
        flex-direction: column;
        flex: 1;

        @include breakpoint-max('s-tablet') {
            flex: 100%;
        }

        input {
            width: 100%;
            padding: 15px 0;
            margin: 10px 0;
            border: none;
            border-bottom: 1px solid $darkGranat;
            font-size: 14px;
            color: $darkGray;
            -webkit-appearance: none;
            background-color: $secondBackgroundColor;
        }
    }

    &__logo {
        max-width: 180px;
        width: 100%;
        margin-top: 30px;
    }

    &__right-form {
        flex: 2;

        textarea {
            width: calc(100% - 10px);
            max-width: 500px;
            border: 1px solid #EBEBEB;
            padding: 15px 15px 15px 5px;
            margin-left: 10px;
            height: 100%;
            font-size: 14px;
            max-height: 167px;
            font-family: 'Poppins', sans-serif;
            resize: none;
            -webkit-appearance: none;
            color: $darkGray;
            background-color: $secondBackgroundColor;

            @include breakpoint-max('s-tablet') {
                width: 100%;
                margin-left: 0;
                max-width: none;
            }
        }
    }

    &__checkbox {
        align-items: left;
        justify-content: flex-start;
        flex-direction: column;
        p {
            font-size: 0.8em;
            margin-top: 10px;
        }

        label {
            color: $darkGray;
            font-size: 12px;
            margin: 0 20px 0 10px;

            a {
                color: $darkGray;
            }
        }
    }

    &__check {
        margin: 10px 0;

        label {
            a {
                text-decoration: underline;
            }
        }
    }

    &__btn {
        background-color: $red;
        border: 0;
        margin-top: 20px;
        padding: 20px 0;
        color: #FFF;
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 300;
        -webkit-appearance: none;
        cursor: pointer;
        width: 100%;
        font-weight: 700;
        margin-bottom: 20px;
    }
}

.popup {
    position: fixed;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.9);
    top: 60%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 1000px;
    width: 90%;
    height: calc(100vh - 100px);
    padding: 50px;
    font-size: 1rem;
    line-height: 25px;

    p {
        margin: 30px 0;
    }

    h2 {
        margin-bottom: 20px;
    }

    &__text {
        height: 100%;
        overflow-y: scroll;
    }

    &__close {
        position: fixed;
        right: 20px;
        top: 0;

        &:after {
            content: "\274c";
            font-size: 20px;
            color: $granat;
            line-height: 100px;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>
