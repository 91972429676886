<template>
  <section>
    <iframe
      src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=7n0gllsk9s"
      width="100%"
      seamless="seamless"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
    ></iframe>
    <a :href="data.download_link">{{ data.download_button }}</a>
  </section>
</template>

<script>
export default {
  name: "Brochure",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
iframe {
  min-height: 90vh;
}
a {
  align-self: center;
  text-align: center;
  color: #fff;
  margin: 10px auto;
  display: block;
  padding: 10px 15px;
  &:hover {
    color: #fff;
  }
}
</style>
