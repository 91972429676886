<template>
    <section class="only-image" :class="[data.custom_class]">
        <div class="only-image__bg"></div>
        <div class="only-image__container custom-container-left">
            <img :src="data.image.url" :alt="data.image.alt" data-aos="fade-up">
        </div>
    </section>
</template>

<script>
export default {
    name: "OnlyImage",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
.only-image {
    margin-bottom: 40px;
    position: relative;
    &__bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    &__container {
        img {
            margin: 0;
            display: block;
        }
    }
    &__margin {
        margin-top: -400px;
    }
}
</style>
