<template>
<section class="intro" :class="[data.custom_class]">
    <div class="intro__container" :class="[data.custom_container]">
        <div class="intro__image-box" :data-aos="switchDirection(data.order_image)" :style="{order: data.order_image, backgroundImage: 'url(' + data.image.url + ')'}"></div>
        <div class="intro__text-box" :data-aos="switchDirection(data.order_text)" :style="{order: data.order_text}">
            <h1 v-html="data.heading">{{ data.heading }}</h1>
            <div v-html="data.content" class="intro__text"></div>
        </div>
        <!--  <div v-if="locale === 'pl'" class="intro__additional-text">
                <p>Przedstawiony materiał jest tylko poglądowy</p>
                <p>i nie stanowi oferty w rozumieniu kodeksu cywilnego</p>
            </div>
            <div v-if="locale === 'en'" class="intro__additional-text">
                <p>The presented material is only for reference</p>
                <p>and does not constitute an offer under the Polish civil code</p>
            </div>
            <div v-if="locale === 'ua'" class="intro__additional-text">
                <p>Представлений матеріал запропонований лише для ознайомлення,</p>
                <p>він не є пропозицією купівлі у розумінні Цивільного кодексу.</p>
            </div> -->
    </div>
    <div class="intro__clause-box">{{ data.clause }}</div>
</section>
</template>

<script>
export default {
    name: "Intro",
    data() {
        return {}
    },
    computed: {
        locale() {
            return this.$store.getters.getLocale
        }
    },
    methods: {
        switchDirection(arg) {
            switch (arg) {
                case '1':
                    return 'fade-right'
                    break;
                case '2':
                    return 'fade-left'
                    break;
                default:
                    return 'fade-right'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.custom-container-right {
    .intro__additional-text {
        left: 0 !important;
    }
}

.intro {
    display: flex;
    padding-bottom: 140px;

    @include breakpoint-max("s-tablet") {
        padding-bottom: 90px;
    }

    &__container {
        display: flex;
        position: relative;

        @include breakpoint-max("s-tablet") {
            flex-direction: column;
        }
    }

    &__text-box {
        padding: 140px 120px;
        flex-direction: column;
        font-size: 18px;

        @include breakpoint-max("large") {
            padding: 100px 80px;
        }

        @include breakpoint-max("tablet") {
            padding: 60px 50px;
        }

        @include breakpoint-max("s-tablet") {
            padding: 40px;
            padding-top: 60px;
        }

        @include breakpoint-max("mobile") {
            padding: 40px 20px;
            padding-top: 60px;
        }
    }

    &__additional-text {
        position: absolute;
        bottom: -60px;
        left: 20px;
        color: #5867a1;
        font-size: 12px;
    }

    &__image-box,
    &__text-box {
        display: flex;
    }

    &__image-box {
        flex: 1;
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 638px;
        margin-top: 200px;
        z-index: 2;

        @include breakpoint-max("s-tablet") {
            min-height: 400px;
            background-position-y: bottom;
            flex: unset;
        }

        @include breakpoint-max("xs-tablet") {
            min-height: 360px;
        }

        @include breakpoint-max("mobile") {
            min-height: 185px;
        }

    }

    &__text-box {
        flex: 1;

        @include breakpoint-max("s-tablet") {
            flex: unset;
        }

        color: $darkBlueHeading;

        h1 {
            font-size: 2.2em;
            font-weight: 400;
            margin-bottom: 20px;
            line-height: 1.25;

            @include breakpoint-max("s-tablet") {
                font-size: 36px;
            }

            @include breakpoint-max("mobile") {
                font-size: 30px;
            }

            /deep/ span {
                color: $lightBlue;
            }
        }
    }
}
</style>
