<template>
<section class="cartImages">
    <div class="cartImages__main custom-container" data-aos="fade-left">
        <h2 v-html="data.title"></h2>
        <div class="cartImages__main--wrapper">
            <div class="cartImages__main--wrap" v-for="(item, index) in data.wrap" :key="index">
                <img :src="item.img" alt="cart images">
                <div class="cartImages__main--wrap__description" v-if="item.text" v-html="item.text"></div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "CartImagesWithText",
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.cartImages {
    padding: 100px 0;

    @include breakpoint-max("mobile") {
        padding: 20px 0;

    }

    &__main {
        h2 {
            font-size: 2.2em;
            font-weight: 300;
            margin-bottom: 60px;
        }

        &--wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        &--wrap {
            width: 50%;
            padding-right: 150px;
            padding-bottom: 80px;
            display: flex;
            align-items: center;

            @include breakpoint-max("v-large") {
                padding: 10px;
            }

            @include breakpoint-max("tablet") {
                width: 100%;
            }

            @include breakpoint-max("mobile") {
                flex-direction: column;
            }

            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                flex-direction: row-reverse;
                padding-right: 135px;

                @include breakpoint-max("v-large") {
                    padding: 10px;
                }

                @include breakpoint-max("mobile") {
                    flex-direction: column;
                }

                img {
                    margin-left: 50px;
                    margin-right: 0;
                    


                    @include breakpoint-max("mobile") {
                        margin: 0;
                    }

                }

            }

            img {
                max-width: 256px;
                min-height: 322px;
                object-fit: cover;
                margin-right: 50px;
                object-position: right ;

                @include breakpoint-max("v-large") {
                    padding: 10px;
                }

                @include breakpoint-max("mobile") {
                    margin: 0;
                }

            }

            /deep/ h3 {
                font-size: 1.5em;
                margin-bottom: 20px;
            }

        }

    }
}
</style>
