<template>
  <section class="slider">
    <div class="custom-container-left">
      <div class="slider__content" ref="content">
        <div class="columns is-paddingless is-marginless">
      
          <div class="column is-7 slider__background">
            
            <img
              :src="data.slide[0].background_image.sizes.large"
              alt=""
              ref="image"
            />
          </div>
          <div class="column is-5 slider__text" ref="text">
            <div class="slider__header">
              <transition v-on:enter="textEnter" v-on:leave="textLeave" appear>
                <h2
                  v-html="item.title"
                  v-for="(item, index) in data.slide"
                  :key="index"
                  v-if="index === currentSlide"
                ></h2>
              </transition>
              <transition v-on:enter="textEnter" v-on:leave="textLeave" appear>
                <div
                  class="slider__moretext"
                  v-for="(item, index) in data.slide"
                  :key="index"
                  v-if="index === currentSlide"
                >
                  <span v-if="locale == 'pl'"
                    ><a :href="item.slide_link">Więcej</a></span
                  >
                  <span v-if="locale == 'en'"
                    ><a :href="item.slide_link">More</a></span
                  >
                  <span v-if="locale == 'ua'"
                    ><a :href="item.slide_link">More</a></span
                  >
                </div>
              </transition>
            </div>
          </div>
        </div>
        <a v-if="locale == 'pl'" :href="data.link" class="slider__button">
          <p>{{ data.button }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer"
            enable-background="new 0 0 64 64"
            height="512px"
            viewBox="0 0 64 64"
            width="512px"
          >
            <g>
              <path
                d="m32 8c-1.104 0-2 .896-2 2v39.899l-14.552-15.278c-.761-.799-2.026-.832-2.828-.069-.8.762-.831 2.027-.069 2.827l16.62 17.449c.756.756 1.76 1.172 2.829 1.172 1.068 0 2.073-.416 2.862-1.207l16.586-17.414c.762-.8.73-2.065-.069-2.827-.799-.763-2.065-.731-2.827.069l-14.552 15.342v-39.963c0-1.104-.896-2-2-2z"
                data-original="#000000"
                class="active-path"
                data-old_color="#000000"
                fill="#FFFFFF"
              />
            </g>
          </svg>
        </a>
        <a v-else :href="data.link" class="slider__button">
          <p>{{ data.button }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer"
            enable-background="new 0 0 64 64"
            height="512px"
            viewBox="0 0 64 64"
            width="512px"
          >
            <g>
              <path
                d="m32 8c-1.104 0-2 .896-2 2v39.899l-14.552-15.278c-.761-.799-2.026-.832-2.828-.069-.8.762-.831 2.027-.069 2.827l16.62 17.449c.756.756 1.76 1.172 2.829 1.172 1.068 0 2.073-.416 2.862-1.207l16.586-17.414c.762-.8.73-2.065-.069-2.827-.799-.763-2.065-.731-2.827.069l-14.552 15.342v-39.963c0-1.104-.896-2-2-2z"
                data-original="#000000"
                class="active-path"
                data-old_color="#000000"
                fill="#FFFFFF"
              />
            </g>
          </svg>
        </a>
      </div>
      <div class="slider__additional-text">
        <div v-if="locale == 'pl'">
          <p>Przedstawiony materiał jest tylko poglądowy</p>
          <p>i nie stanowi oferty w rozumieniu kodeksu cywilnego</p>
        </div>
        <div v-else-if="locale == 'ua'">
          <p>Представлений матеріал запропонований лише для ознайомлення,</p>
          <p>він не є пропозицією купівлі у розумінні Цивільного кодексу.</p>
        </div>
        <div v-else>
          <p>The presented material is only for reference</p>
          <p>and does not constitute an offer under the Polish civil code</p>
        </div>
      </div>
    </div>
    <transition v-on:enter="enter" v-on:leave="leave" appear>
      <div
        class="slider__people"
        v-for="(item, index) in data.slide"
        :class="item.people_custom_class"
        :key="index"
        v-if="index == currentSlide"
      >
        <img :src="item.people.sizes.large" ref="people" alt="" />
      </div>
    </transition>
  </section>
</template>

<script>
import { TimelineLite, Back, Elastic, Expo } from "gsap";

export default {
  data() {
    return {
      currentSlide: 3,
    };
  },
  computed: {
    locale() {
      return this.$store.getters.getLocale;
    },
  },
  methods: {
    startSlider() {
      setInterval(() => {
        if (this.data.slide.length - 1 > this.currentSlide) {
          this.currentSlide++;
        } else {
          this.currentSlide = 0;
        }
      }, 4000);
    },
    enter(el, done) {
      const tl = new TimelineMax({
        onComplete: done,
      });

      tl.set(el, {
        webkitFilter: "blur(3px)",
        filter: "blur(3px)",
        opacity: 0,
        x: -50,
      });

      tl.to(el, 5, {
        x: 30,
        ease: Power1.easeOut,
      });

      tl.to(
        el,
        1.5,
        {
          webkitFilter: "blur(0px)",
          filter: "blur(0px)",
          x: 30,
          opacity: 1,
          ease: Power1.easeOut,
        },
        "-=5"
      );
    },
    leave(el, done) {
      TweenMax.to(el, 0.6, {
        opacity: 0,
        x: 50,
        ease: Power1.easeOut,
        onComplete: done,
      });
    },
    textEnter(el, done) {
      const tl2 = new TimelineMax({
        onComplete: done,
      });

      tl2.set(el, {
        opacity: 0,
        x: -30,
      });

      tl2.to(el, 1.5, {
        x: 0,
        opacity: 1,
        ease: Power1.easeOut,
      });
    },
    textLeave(el, done) {
      TweenMax.to(el, 0.3, {
        opacity: 0,
        x: 50,
        ease: Power1.easeOut,
        onComplete: done,
      });
    },
  },
  created() {
    this.startSlider();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.slider {
  &__more {
    position: relative;
  }

  &__moretext {
    position: absolute;
    bottom: 30px;
    left: 190px;
    right: 120px;

    z-index: 1000;

    a {
      text-transform: uppercase;
      color: #848484;
      line-height: 100%;
    }

    a:hover {
      color: #848484;
    }

    a::before {
      content: "";
      line-height: 1;
      display: inline-block;
      background-image: url(/img/arrow.min.9b9f6b65.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      width: 1.5rem;
      height: 1.25rem;
      vertical-align: middle;
      margin-right: 10px;
      -webkit-transition: all 0.35s ease-in-out;
      transition: all 0.35s ease-in-out;
      position: absolute;
      left: -40px;
    }

    @include breakpoint-max("tablet") {
      left: 190px;
      bottom: 70px;
    }
    @include breakpoint-max("s-tablet") {
      left: 190px;
      bottom: 40px;
    }
    @include breakpoint-max("mobile") {
      left: 100px;
      bottom: 40px;
    }
  }

  padding-bottom: 200px;
  position: relative;
  overflow: hidden;
  @include breakpoint-max("s-tablet") {
    padding-bottom: 100px;
  }

  &__content {
    background-color: #fff;
    position: relative;
  }

  &__additional-text {
    position: absolute;
    bottom: 150px;
    color: #5867a1;
    font-size: 12px;
    left: 20px;
    @include breakpoint-max("s-tablet") {
      bottom: 35px;
    }
  }

  &__text {
    padding: 90px 0;
    @include breakpoint-max("v-large") {
      flex: 50% !important;
    }
    @include breakpoint-max("s-tablet") {
      padding: 0px;
    }
  }

  &__header {
    position: relative;
    min-height: 400px;
    width: 100%;
    @include breakpoint-max("s-tablet") {
      min-height: 250px;
    }
    @include breakpoint-max("s-tablet") {
      min-height: 200px;
    }

    /deep/ h2 {
      font-weight: 600;
      color: $granat;
      position: absolute;
      top: 50%;
      left: 120px;
      right: 120px;
      font-size: 64px;
      line-height: 70px;
      transform: translate(-50%, -50%);
      @include breakpoint-max("large") {
        font-size: 56px;
        line-height: 64px;
      }
      @include breakpoint-max("tablet") {
        font-size: 48px;
        line-height: 58px;
        width: 100%;
      }
      @include breakpoint-max("s-tablet") {
        font-size: 26px;
        line-height: 38px;
      }
      @include breakpoint-max("mobile") {
        left: 50px;
        font-size: 24px;
        line-height: 38px;
      }

      span {
        color: $lightBlue;
      }
    }
  }

  &__list {
    color: $granat;
    font-weight: 300;

    /deep/ ul {
      li {
        margin-top: 35px;
        position: relative;
        margin-left: 25px;

        &::before {
          content: "";
          width: 15px;
          height: 1px;
          background-color: $lightBlue;
          position: absolute;
          left: -25px;
          top: 15px;
        }
      }
    }
  }

  &__background {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include breakpoint-max("v-large") {
      flex: 50% !important;
    }
    @include breakpoint-max("s-tablet") {
      min-height: 300px;
    }
  }

  &__people {
    position: absolute;
    bottom: 0;
    left: 22.5%;
    @include breakpoint-max("v-large") {
      left: 250px;
    }

    img {
      max-height: 700px;
      margin-bottom: -15px;
      @include breakpoint-max("v-large") {
        max-height: 550px;
      }
    }
  }

  .couple {
    left: 27%;
    @include breakpoint-max("v-large") {
      left: 25%;
    }
    @include breakpoint-max("tablet") {
      left: 17%;
    }
    @include breakpoint-max("s-tablet") {
      display: none;
    }
  }

  .family {
    left: 19.5%;
    max-width: 800px;
    @include breakpoint-max("v-large") {
      left: 10%;
    }
    @include breakpoint-max("large") {
      left: 4%;
    }
    @include breakpoint-max("tablet") {
      left: 0%;
    }
    @include breakpoint-max("s-tablet") {
      display: none;
    }
  }

  .women {
    left: 35%;
    @include breakpoint-max("v-large") {
      left: 25%;
    }
    @include breakpoint-max("s-tablet") {
      display: none;
    }
  }

  .young-couple {
    left: 20%;
    @include breakpoint-max("v-large") {
      left: 20%;
    }
    @include breakpoint-max("tablet") {
      left: 10%;
    }
    @include breakpoint-max("s-tablet") {
      display: none;
    }
  }

  &__button {
    background-color: #fc0d1b;
    position: absolute;
    right: 40px;
    // bottom: -34px;
    padding: 4px 6px 4px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    @include breakpoint-max("xs-tablet") {
      right: 24px;
      bottom: -29px;
      padding: 4px 4px 4px 6px;
    }
    @include breakpoint-max("mobile") {
      padding: 2px 3px 2px 6px;
      bottom: 0;
      right: 0;
    }

    p {
      color: #fff;
      font-size: 18px;
      @include breakpoint-max("xs-tablet") {
        font-size: 14px;
      }
    }

    svg {
      height: 20px;
      width: 20px;
      padding-left: 4px;
      @include breakpoint-max("xs-tablet") {
        height: 20px;
        width: 18px;
      }
    }
  }
}
</style>
