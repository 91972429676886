<template>
  <section class="map iconsWithText" :class="data.custom_class">
    <div class="map__background-div"></div>
    <div class="map__container custom-container custom-container">
      <div class="map__map-box" data-aos="fade-right">
        <div class="map__map-box-text">
          <h2 v-if="data.title">{{ data.title }}</h2>
          <p v-if="data.text" v-html="data.text"></p>
          <img v-if="data.img" :src="data.img" >
        </div>
        
      </div>
      <div class="map__text-box" data-aos="fade-left">
        <div class="map__text-box-main-wrap">
          <div class="map__text-box-column-one">
            <div
              class="map__text-box-wrap"
              v-for="(item, index) in data.localization"
              :key="index"
            >
              <div
                class="map__text-box-wrap-icon"
                v-if="item.icon"
                :style="{ backgroundImage: 'url(' + item.icon + ')' }"
              ></div>
              <h3 v-if="item.number" v-html="item.number"></h3>
              <p v-if="item.description" v-html="item.description"></p>
            </div>
          </div>
          <div class="map__text-box-column-two">
            <div
              class="map__text-box-wrap"
              v-for="(item, index) in data.localization_right"
              :key="index"
            >
              <div
                class="map__text-box-wrap-icon"
                v-if="item.icon"
                :style="{ backgroundImage: 'url(' + item.icon + ')' }"
              ></div>
              <h3 v-if="item.number" v-html="item.number"></h3>
              <p v-if="item.description" v-html="item.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: "IconsWithText",
  data() {
    return {};
  },
  computed: {
    locale() {
      return this.$store.getters.getLocale;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.extra-p-y {
  padding: 120px 0 0;

  @include breakpoint-max("tablet") {
    padding: 80px 0;
  }

  @include breakpoint-max("s-tablet") {
    padding: 60px 0;
  }

  @include breakpoint-max("xs-tablet") {
    padding: 20px 0 100px 0;
  }
}

.mobile-col-reverse {
  .map__container {
    @include breakpoint-max("xs-tablet") {
      flex-direction: column-reverse;
    }
  }
}

.map {
  position: relative;
  color: $darkGranat;
  font-weight: 300;
  margin-top: 50px;

  &__background-div {
    background-color: $secondBackgroundColor;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 700;
  }

  &__container {
    z-index: 800;
    display: flex;

    @include breakpoint-max("xs-tablet") {
      flex-direction: column;
    }
  }

  &__map-box {
    flex: 7;
    width: 100%;
    background-size: cover;
    background-position: center;

    @include breakpoint-max("xs-tablet") {
      min-height: 300px;
      height: 100px;
    }

    &-text {
      width: 100%;
      margin: auto;
      position: relative;
      margin-bottom: 100px;

      span {
        font-weight: 700;
      }

      h2 {
        font-size: 2.2em;
        line-height: 1em;
        margin: 20px 0 30px 0;
      }
      img {
          margin-top: 50px;
      }

    }
  }

  &__text-box {
    flex: 6;
    padding: 50px 120px;
    font-size: 18px;
    font-weight: 300;
    display: block;

    &-main-wrap {
      display: flex;

      @include breakpoint-max("mobile") {
        flex-direction: column;
      }
    }

    &-column-one {
      margin-right: 50px;
      min-width: 225px;

      @include breakpoint-max("mobile") {
        min-width: 100px;
        margin-right: 0;
      }
    }

    &-column-two {
      min-width: 225px;

      @include breakpoint-max("mobile") {
        min-width: 100px;
      }
    }

    &-wrap {
      border-bottom: 2px solid lightgray;
      padding: 30px 0;

      @include breakpoint-max("mobile") {
        text-align: center;
      }

      h3 {
        font-size: 2em;
      }

      &-icon {
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(14%) sepia(20%) saturate(885%) hue-rotate(198deg)
          brightness(99%) contrast(93%);

        @include breakpoint-max("mobile") {
          margin: auto;
        }
      }
    }

    &-more {
      margin: 20px 0;

      a {
        position: relative;

        &::after {
          content: "";
          height: 15px;
          width: 15px;
          background-image: url("~@/assets/arrow-right.svg");
          background-size: cover;
          position: absolute;
          top: 5px;
          right: -30px;
          filter: invert(45%) sepia(53%) saturate(3214%) hue-rotate(334deg)
            brightness(96%) contrast(104%);
        }
      }
    }

    @include breakpoint-max("tablet") {
      padding: 80px 60px;
    }

    @include breakpoint-max("s-tablet") {
      padding: 60px 40px;
    }

    @include breakpoint-max("xs-tablet") {
      padding: 40px 0;
    }
  }

  &__subheading {
    color: $lightBlue;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 5px;
    display: inline-block;
  }

  &__heading {
    color: $darkBlueHeading;
    font-weight: bold;
    font-size: 26px;
    letter-spacing: 0.5px;
  }

  &__heading,
  &__text {
    margin-bottom: 24px;
  }

  &__text {
    color: $darkGranat;
  }

  &__link {
    text-transform: uppercase;
    color: $darkBlueHeading;
    letter-spacing: 1.25px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      line-height: 1;
      display: inline-block;
      background-image: url("../../assets/arrow.min.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      width: 1.5rem;
      height: 1.25rem;
      vertical-align: middle;
      margin-right: 10px;
      transition: all 0.35s ease-in-out;
    }
  }

  &__googlemap {
    width: 100%;
    height: 500px;
    position: relative;
  }

  &__iframe {
    width: 100%;
    height: 100%;
    max-height: 650px;

    @include breakpoint-max("mobile") {
      max-height: 350px;
    }
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $orange;
    z-index: 800;
    color: #000;

    &--margin {
      margin: 20px;

      h2 {
        font-family: "Gilroy-bold";
      }

      hr {
        background-color: #000;
        height: 1px;
      }
    }

    img {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
}
</style>
